import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {BookingService,HomeService, DataService, PassengerService, ItineraryServiceService } from '../services/index'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { formatDate   } from '@angular/common';
import * as $ from 'jquery';
import { PassDestinationValueService } from '../services/pass-destination-value.service';
import { PassDepatureValueService } from '../services/pass-depature-value.service';
import { ScrolltopService } from '../services/scrolltop.service';
import { Base64 } from 'js-base64';
import flightDestinations from '../../assets/data/destinations.json';
import * as moment from 'moment';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {
  @ViewChild('focustarget') focusEl: ElementRef;

  reactiveForm: UntypedFormGroup;reactiveMobileForm: UntypedFormGroup;
  fromPlaceholder="From";
  toCityCode="To";
  fromCityCode="From";
  toPlaceholder="To";
  passengerPlaceholder=" pasenger"
  fromMobilePlaceholder=''
  toMobilePlaceholder=''
  public pickupplaceholder: string = 'Boarding Point';
  public dropoffplaceholder: string = 'Dropoff Point';
  public keyword = 'name';
  checkiftoday:boolean;
  public pickplaceholder: string = 'Enter Pick Up';
  public destplaceholder: string = 'Enter Drop Off';
  public historyHeading: string = 'Recently selected';
  today=new Date();
  isOpen=false;
  bsValue;
  bsInlineValue;
  destinationLoading=false;
  beforeyesterday:Date =( d => new Date(d.setDate(d.getDate()-2)) )(new Date);
  yesterday:Date = ( d => new Date(d.setDate(d.getDate()-1)) )(new Date);
  tomorrow:Date = ( d => new Date(d.setDate(d.getDate()+1)) )(new Date);
  aftertomorrow:Date = ( d => new Date(d.setDate(d.getDate()+2)) )(new Date);
  //traveldate:any;
  data = [];formdata={};
  formdataTravel={};
  formdataReturn={};
  private sub: any;
  res: any = {};
  return_schedule:any;
  schedule: any ={};
  routename: any ={};
  pickup:any =[];
  dropoff:any =[];
  dummyDropoff:any =[];
  seatsSelected: any=[];
  fleet: any ={};
  seat_price: any ={};
  params:any;
  bookingdetails: any;
  results: any ;
  isResultsLoading=false;
  seatErrors=false;
  showSeats=false;
  changeI=false;
  pickUpInit: string;
  dropOffInit: string;
  returnPickUpInit: string;
  returnDropOffInit: string;
  travelDateInit: string;
  returnDateInit:string;
  isUnavailabe=false;
  dataLoading=false;
  isPickupClicked=false;
  isReturnTicket=false;
  isReturnTicketSelected=false;
  isShowingFilter=false;
  totalFare=0;
  returning=false;
  passengers:any;
  submitted=false;
  selectedIndex = -1;
  hide_from_ui:number;
  availableSeats=[];
  seats =[];
  seatData:any =[];
  seatMobile:any =[];
  seatDataNew:any =[];
  seatRes:any;
  busCheckSelected:any;
  isReadOnly=false;
  types= [];
  sticky: boolean = false;
  elementPosition: any;
  busSelected:any;
  isBusSelected:boolean =false;
  busOperatorSelected:any;
  selectedBus:any;
  currentBusId:any;
  selectedBusStatus:boolean=false;
  isAcClicked=false;
  isEmptyReturn=false;
  isWater=false;
  finalTravelDate: string;
  finalReturnDateInit:string;

  noOfDaysToReturn: any;
  midTripFlight: any;
  layOver: any;
  dayAbbrList: any = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  monthAbbrList: any = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

     // Travel Duqa
     sampleData = [
      {
          "name": "Jomo Kenyatta International",
          "city": "Nairobi",
          "country": "Kenya",
          "iata": "NBO"
      },
      {
          "name": "Nairobi Wilson",
          "city": "Nairobi",
          "country": "Kenya",
          "iata": "WIL"
      }
  ]
  todayDate = formatDate(new Date(),"yyyy-MM-dd","en-KE");
  getYesterdaydate:Date = ( d => new Date(d.setDate(d.getDate()-1)) )(new Date);
  thisYesterdayDate=new Date();
  thistodayDate= new Date()
  //flights var def
    availableFlights:any=[];
    isavailableFlights: boolean = false
    classType:string;
    logoUrl= 'https://images.kiwi.com/airlines/64x64/'
    logoUrlsmall= 'https://images.kiwi.com/airlines/32x32/'
    airlinesData:any;
    loadingCheckFlights=false;
    isArrayData=false;
    passengersData:any;
    totalpassenger:any;
    dataholderTo:any;
    dataholderFrom:any;
  //end flights data
  constructor(
    private router: Router,
    private service:BookingService,
    private dataService:DataService,
    private _fb: UntypedFormBuilder,
    private itenaryservice: ItineraryServiceService,
    private passengerService:PassengerService,
    private serviceDestination: PassDestinationValueService,
    private serviceDepature:PassDepatureValueService,

  ) {

    
 
    var d = formatDate(this.getYesterdaydate,"yyyy-MM-dd","en-KE");
    this.thisYesterdayDate = new Date(d)
    this.thistodayDate=new Date(this.todayDate)
    this.reactiveForm = _fb.group({
      pickup:['', Validators.required],
      dropoff:['', Validators.required],
      traveldate:['', Validators.required],
      passenger:[null,Validators.required],
      returndate:[new Date(), Validators.required]
    });
    this.reactiveMobileForm = _fb.group({
      pickup:['', Validators.required],
      dropoff:['', Validators.required],
      traveldate:[new Date(), Validators.required]
    });

    this.passengerService.passengerValue.subscribe(passenger => {
      this.passengersData=passenger;
      this.totalpassenger = this.passengersData.adultpassenger+this.passengersData.childpassenger+this.passengersData.infantpassenger
      this.reactiveForm.patchValue({passenger: this.totalpassenger + ' Passenger,' +  this.passengersData.classType})

    });
   }

   public flightCities:{name:string, code:string}[] = flightDestinations;


    onTravelDateChange = (event) =>{
      var traveldate = formatDate(event,"yyyy-MM-dd","en-KE");
      this.today = event;
    }

    onReturnDateChange = (event) =>{
       var returndate = formatDate(event,"yyyy-MM-dd","en-KE");
    }

    returnClicked(){
      this.returning=!this.returning;
      this.isOpen=true;
    }

    closeReturn = () =>{
      this.returning=false;
    }

    showFilter () {
      this.isShowingFilter=true;
    }

    hideFilter() {
      this.isShowingFilter=false;
    }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
             if (!(evt instanceof NavigationEnd)) {
                 return;
             }
             window.scrollTo(0, 0)
         });

    this.formControlValueChanged();
    this.dataService.currentMessage.subscribe(message =>{
      if(!message){
        message=localStorage.getItem('searchFlights');
        if(!message){
          this.router.navigate(['/index.html'])
        }
      }
      // localStorage.setItem('searchFlights', message);
      this.bookingdetails = JSON.parse(Base64.decode(message))
      this.onSearch(JSON.parse(Base64.decode(message)));
     
    });

    this.checkDates();

  }


  ngAfterViewInit(): void {
    // Hack: Scrolls to top of Page after page view initialized
    let top = document.getElementById('tophome');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  formControlValueChanged() {
    this.reactiveForm.get('pickup').valueChanges.subscribe(
        (mode: string) => {

        });

        this.reactiveForm.get('dropoff').valueChanges.subscribe(
          (mode: string) => {
          });
}

  // getArlinesRequest(){
  //   this.service.getAirlines().subscribe(
  //     response => {
  //       this.dataLoading=false;
  //       this.res = response;
  //       const entries = Object.entries(this.res);
  //       let fdata=[];
  //       for (let i = 0; i < entries.length; i++) {
  //         let aril:any = entries[i][1]
  //         fdata[aril.id]=aril.name
  //       }
  //       this.airlinesData = fdata 
  //     },
  //     // error => {
  //     // }
  //   );
  // }
  interChangeValues(pickup,dropoff){
    this.fromPlaceholder = pickup.name;
    this.toPlaceholder = dropoff.name;
  }
  checkDates=() =>{
    let traveldate = this.travelDateInit;
    let today = formatDate(this.today,"yyyy-MM-dd","en-KE");
    var date = new Date(traveldate);
    this.beforeyesterday=new Date(date.setDate(date.getDate()-2));
    this.yesterday=new Date(date.setDate(date.getDate()+1));
    this.tomorrow =new Date(date.setDate(date.getDate()+2))
    this.aftertomorrow =new Date(date.setDate(date.getDate()+2));
      if(traveldate == today)
        return true;
      else
        return false;
  }
  changeDate=(selected) =>{
    this.travelDateInit=formatDate(selected,"yyyy-MM-dd","en-KE");
    this.checkDates();
    this.bookingdetails.departure_on = this.travelDateInit
    this.onSearch(this.bookingdetails);
  }

  getFlightDetails(result){
    //check if selected flight is aerocrs
    let dataf=[]

    let  final_data = {
      "flight_data":result,
      "passengers":this.passengers,
   
    }
    
      let pay=Base64.encode(JSON.stringify(final_data));
      let paymessage=btoa("Opps sory mate!");
      this.dataService.changePaymentMessage(pay);
      this.router.navigate(['/payments', paymessage])


  }


  interChange=()=>{
    this.changeI=!this.changeI;
    if(this.changeI){
       const dropOff = this.data.find(city => city === this.dropOffInit);
       const pickUp = this.data.find(city => city === this.pickUpInit);
      let webformdata={
        'pickup' : dropOff,
        'dropoff' : pickUp,
        'pickupname' : dropOff,
        'dropoffname' :pickUp,
        'traveldate' :this.travelDateInit,
        'returndate' :this.returnDateInit,
        'returning' : this.returning
      }
      this.interChangeValues(dropOff,pickUp);
      // TO DO
      // this.onSearch(webformdata)
  
    }else{
       const dropOff = this.data.find(city => city === this.dropOffInit);
       const pickUp = this.data.find(city => city === this.pickUpInit);
      let webformdata={
        'pickup' : pickUp,
        'dropoff' : dropOff,
        'pickupname' : pickUp,
        'dropoffname' :dropOff,
        'traveldate' :this.travelDateInit,
        'returndate' :this.returnDateInit,
        'returning' : this.returning
      }
      this.interChangeValues(pickUp,dropOff);
      // TO DO
      // this.searchBuses(webformdata);
    }
  }

  // get flights destinations( city and city code)
  // private loadDestinations= () => {
  //   this.destinationLoading=true;
  //   this.data=this.flightCities;

  //   this.homeservice.getCredentials().subscribe(
  //     response => {
  //       this.res = response;
  //     //   let data = {
  //     //     "grant_type":"client_credentials",
  //     //     "client_id":4,
  //     //     "client_secret":this.res.data.data.flight_client_secret
  //     // }
      
  //     //   this.homeservice.getApiToken(data).subscribe( resp =>{
  //     //     let d = resp.access_token;
  //     //     this.homeservice.saveToken(d);
  //     //   })
  //     },
  //     error => {
  //       this.router.navigate(['/index.html']);
  //     }
  //   );
  // }
  backHome(){
    this.router.navigateByUrl('/home');
  }
  forMatThisData = (data) => {
    return formatDate(data,"dddd dS, M","en-KE");
  }


  onSearch(data) {
    this.passengers ={
      "adult":data.adult_count,
      "child":data.child_count,
      "infant":data.infant_count,
      "total_passengers":data.adult_count + data.child_count + data.infant_count
    }

    let mdata ={
        "departure_on":data.departure_on,
        "arrival_on": data.arrival_on,
        "trip_type":data.trip_type,
        "adult_count":data.adult_count,
        "child_count":data.child_count,
        "infant_count":data.infant_count,
        "travel_class": data.travel_class
      
    }

// set UI values
    this.pickUpInit=this.bookingdetails.from_data.iata;
    this.dropOffInit=this.bookingdetails.destination_data.iata;
    this.travelDateInit=this.bookingdetails.departure_on;
    this.returnDateInit=this.bookingdetails.arrival_on;
    this.fromPlaceholder = this.bookingdetails.from_data.iata;
    this.toPlaceholder = this.bookingdetails.destination_data.iata;
    this.dataholderTo = this.bookingdetails.destination_data.name;
    this.dataholderFrom = this.bookingdetails.from_data.name;
    this.fromMobilePlaceholder = this.bookingdetails.from_data.city;
    this.toMobilePlaceholder=this.bookingdetails.destination_data.city
    this.classType = this.bookingdetails.travel_class;
    this.toCityCode = this.bookingdetails.destination_data.iata;
    this.fromCityCode = this.bookingdetails.from_data.iata;
    this.returning=this.bookingdetails.returning;
    this.serviceDepature.changeValue(this.pickUpInit);
    this.serviceDestination.changeValue(this.dropOffInit);
    this.bsValue=new Date(formatDate(this.bookingdetails.departure_on,"yyyy-MM-dd","en-KE"));

    this.availableFlights = []
    this.isavailableFlights = false
    this.isResultsLoading=true;
    this.itenaryservice.getFlightSchedule(
          data.depature_city,
          data.destination_city,
          mdata).subscribe(
      (nextdata) => {
    
        this.availableFlights = nextdata
        this.isavailableFlights = true
        this.isResultsLoading = false

      }
    )
  }
  
  // submitWebForm() {
  //   this.submitted = true;
  //    let value = this.reactiveForm.value;
  //    var pattern = /^(\d{1,2})-(\d{1,2})-(\d{4})$/;
  //    if (typeof value.traveldate === 'string' || value.traveldate instanceof String){
  //      var arrayDate = value.traveldate.match(pattern);
  //      var dt = new Date(arrayDate[3], arrayDate[2] - 1, arrayDate[1]);
  //      var traveldate = formatDate(dt,"yyyy-MM-dd","en-KE");
  //    }else{
  //      var traveldate = formatDate(value.traveldate,"yyyy-MM-dd","en-KE");

  //    }

  //    var returndate='';
  //    if(this.returning){
  //       this.returning=true;
  //           if (typeof value.traveldate === 'string' || value.traveldate instanceof String){
  //             var ptrn = /^(\d{1,2})-(\d{1,2})-(\d{4})$/;
  //             var returndate = formatDate(value.returndate,"yyyy-MM-dd","en-KE");
  //           }else{
  //             var returndate = formatDate(value.returndate,"yyyy-MM-dd","en-KE");
  //           }
  //      value.returndate=returndate;
  //    }else{
  //        this.returning=false;
  //    }

  //    if(!this.isObject(value.pickup)){
  //     value.pickup=this.bookingdetails.pickupname
  //     this.bookingdetails.dropoffname
  //    }

  //    if(!this.isObject(value.dropoff)){
  //     value.dropoff=this.bookingdetails.dropoffname
  //    }


  //   let webformdata={
  //     'pickup' : value.pickup,
  //     'dropoff' : value.dropoff,
  //     'pickupname' : value.pickup,
  //     'dropoffname' : value.dropoff,
  //     'traveldate' : traveldate,
  //     'returndate': returndate,
  //     'returning' : this.returning,
  //     'adults' : this.passengersData.adultpassenger,
  //     'children' : this.passengersData.childpassenger,
  //     'infants' : this.passengersData.infantpassenger,
  //     'selected_cabin': this.passengersData.class,
  //     'classType': this.passengersData.classType,
  //   }
  //   this.bookingdetails = webformdata
  //   // TO DO
  //   // this.searchBuses(webformdata);
  //   let p=Base64.encode(JSON.stringify(webformdata));
  //   this.dataService.changeMessage(p);
  // }

  // submitMobileForm() {
  //   this.submitted = true;
  //    let value = this.reactiveMobileForm.value;
  //   var traveldate = new Date(formatDate(value.traveldate,"yyyy-MM-dd","en-KE"));
  //   var returndate = formatDate(value.returndate,"yyyy-MM-dd","en-KE");
  //   value.traveldate=traveldate;
  //   let mobileformdata={
  //     'pickup' : value.pickup,
  //     'dropoff' : value.dropoff,
  //     'pickupname' : value.pickup,
  //     'dropoffname' : value.dropoff,
  //     'traveldate' : traveldate,
  //     'returndate' : returndate,
  //   }
  //   // TO DO
  //   // this.searchBuses(mobileformdata);
  // }

  toggleShow(results: any) {
      this.seatsSelected=[];
      this.totalFare=0;
      this.dataLoading=false;
      this.hide_from_ui = 0;
      $("#mySidenav").width('0px');
      $(".fadeMe").hide();
    }



    get f() { return this.reactiveForm.controls; }

  // submitFlightForm(result:any) {
  //   let pay=btoa(JSON.stringify('Testflightsbrother'));
  //   let paymessage=btoa("Opps sory mate!");
  //   this.dataService.changeMessage(pay);
  //   this.router.navigate(['/payments', paymessage])
  // }


    // // Submit the seat selection for payment processing
    // submitForm(result:any) {
    //   this.submitted = false;
    //   $("#mySidenav").width('0px');
    //   $(".fadeMe").hide();
    //   if (result.dropoff_points.length <1 ) result.dropoff_points.push(this.dropOffInit);
    //   if (result.boarding_points.length <1 ) result.boarding_points.push(this.pickUpInit);
    //   if(this.isReturnTicket && !this.isReturnTicketSelected){
    //     this.isReturnTicketSelected=true;
    //     let resultsData:any;
    //     let resultSeats:any;
    //     resultSeats=this.seatsSelected;
    //     resultsData=result;
    //     // var momentObj = moment(resultsData.departure_date, 'MM-DD-YYYY');
    //     this.formdataReturn={
    //       'result':resultsData,
    //       'fare': resultsData.fare,
    //       'title': resultsData.from + ' to ' + resultsData.to,
    //       'total_fare':this.totalFare,
    //       "search_details": this.bookingdetails,
    //       'pickup' : resultsData.boarding_points.length,
    //       'dropoff' :resultsData.dropoff_points,
    //       'totalfare' : this.totalFare,
    //       'seatsSelected' : resultSeats
    //     }
    //   }else{
    //     let initialData:any;
    //     initialData=result;
    //     //initialData.departure_date = this.finalTravelDate;
    //     this.formdataTravel={
    //       'result':initialData,
    //       'fare': initialData.fare,
    //       'title': initialData.from + ' to ' + initialData.to,
    //       'total_fare':this.totalFare,
    //       "search_details": this.bookingdetails,
    //       'pickup' : initialData.boarding_points.length,
    //       'dropoff' :initialData.dropoff_points,
    //       'totalfare' : this.totalFare,
    //       'seatsSelected' : this.seatsSelected

    //     }
    //   }
    //   //check if the trip is one way
    //   if(this.bookingdetails.returning && !this.isReturnTicket){
    //     this.interChangeValues(this.bookingdetails.dropoff,this.bookingdetails.pickup);
    //     let returnsearch={
    //       'pickup' : this.bookingdetails.dropoff,
    //       'dropoff' : this.bookingdetails.pickup,
    //       'pickupname' : this.bookingdetails.dropoff,
    //       'dropoffname' :this.bookingdetails.pickup,
    //       'traveldate' :this.bookingdetails.arrival_on,
    //       'returndate' :'',
    //     }
    //     this.isReturnTicket=true;
    //     this.interChangeValues(this.bookingdetails.dropoff,this.bookingdetails.pickup);
    //     // TO DO
    //     // this.searchBuses(returnsearch);
    //   } else if(this.bookingdetails.returning && this.isReturnTicket){
    //     let paytravel=btoa(JSON.stringify(this.formdataTravel));
    //     let payreturn=btoa(JSON.stringify(this.formdataReturn));
    //     let syncData={
    //         'travel':paytravel,
    //         'return':payreturn,
    //         'returning':true
    //     }
    //     let pay=btoa(JSON.stringify(syncData));
    //     let paymessage=btoa("Opps sory mate!");
    //     this.dataService.changeMessage(pay);
    //     this.router.navigate(['/payments', paymessage])
    //   } else {
    //     let paytravel=btoa(JSON.stringify(this.formdataTravel));
    //       let syncData={
    //         'travel':paytravel,
    //         'return':'',
    //         'returning':false
    //       }
    //     let pay=btoa(JSON.stringify(syncData));
    //     let paymessage=btoa("Opps sory mate!");
    //     this.dataService.changeMessage(pay);
    //     this.router.navigate(['/payments', paymessage])
    //   }
    // }






  trackByBooking(result:any) {
    return result.id;
 }


  isObject(val) {
    return (typeof val === "object" && !Array.isArray(val) && val !== null);
  }

 acClick(){
   if(this.isAcClicked){
     this.isAcClicked=false;
   }else{
     this.isAcClicked=true;
   }

 }
isWaterClicked(){

  if(this.isWater){
    this.isWater=false;
  }else{
    this.isWater=true;
  }

}

logData(f){

}

checkIfArray(arr){

 return  Array.isArray(arr)
}

getJsonDepature(json){
    return JSON.parse(json).departure
}

getJsonReturn(json){

  return JSON.parse(json).return
}
claTimeDiff(t1:any,t2:any){
  var a = moment(t1, 'HH:mm');
    var b = moment(t2, 'HH:mm');
    let diff:any = a.diff(b, 'hours', true)
    return diff;
    //return 10
}

}
