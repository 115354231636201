import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { ItineraryauthService } from './index'
import  { environment } from '../../environments/environment'

export interface FlightSearchInterface {
  name: string
  city: string
  country: string
  iata: string
}

@Injectable({
  providedIn: 'root'
})
export class ItineraryServiceService {

  public apiUrl  = environment.apiUrl
  public SearchURL = environment.getCities;
  private airportsUrl = 'assets/airports.json'; // Path to local JSON file

  constructor(private http: HttpClient, private auth:ItineraryauthService) {}

  getFlights(data:any): Observable<FlightSearchInterface[]>{
    return this.auth.getToken().pipe(
      switchMap((token): Observable<any> => {
        let params = new HttpParams();
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            params = params.set(key, data[key]);
          }
        }
        console.log(token)
        let headers = new HttpHeaders()
        headers = headers.append('Authorization', `Bearer ${token}`)
        return this.http
          .get(this.SearchURL, { params: params, headers:headers});
      })
    )
  }

   getAirports(): Observable<FlightSearchInterface[]> {
    return this.http.get<{[key: string]: FlightSearchInterface}>(this.airportsUrl).pipe(
      map(response => this.convertObjectToArray(response)),
      catchError(error => {
        console.error('Error fetching airport data:', error);
        return of([]);
      })
    );
  }

  private convertObjectToArray(airports: {[key: string]: FlightSearchInterface}): FlightSearchInterface[] {
    return Object.keys(airports).map(key => airports[key]);
  }

  public searchAirports(query: string): Observable<FlightSearchInterface[]> {
    return this.getAirports().pipe(
      map(airports => airports.filter(airport =>
        airport.city.toLowerCase().includes(query.toLowerCase()) ||
        airport.name.toLowerCase().includes(query.toLowerCase()) ||
        airport.country.toLowerCase().includes(query.toLowerCase())
      ))
    );
  }

  getFlightSchedule( departure:string, destination:string, data:any) {

    return this.auth.getToken().pipe(
      switchMap((token): Observable<any> => {
        let params = new HttpParams();
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            params = params.set(key, data[key]);
          }
        }
        let headers = new HttpHeaders()
        headers = headers.append('Authorization', `Bearer ${token}`)
        return this.http
          .get(`${this.apiUrl}schedules/flights/${departure}/${destination}/`, {params: params, headers: headers})
          .pipe(map((response: any) => {
            return response.data
          }))
      })
    );



  }


  holdFlightBooking(data:any) {
    return this.auth.getToken().pipe(
      switchMap((token): Observable<any> => {
        let params = new HttpParams();
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            params = params.set(key, data[key]);
          }
        }
        let headers = new HttpHeaders()
        headers = headers.append('Authorization', `Bearer ${token}`)

        return this.http.post(`${this.apiUrl}booking/hold/flights/`,data,{
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization','Bearer '+token)
        });
       
      })
    );
  }

  checkFlightBooking(data:any) {
    return this.auth.getToken().pipe(
      switchMap((token): Observable<any> => {
        let params = new HttpParams();
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            params = params.set(key, data[key]);
          }
        }
        let headers = new HttpHeaders()
        headers = headers.append('Authorization', `Bearer ${token}`)

        return this.http.post(`${this.apiUrl}booking/check/`,data,{
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization','Bearer '+token)
        });
       
      })
    );
  }

  initiateStk(data:any) {
    return this.auth.getToken().pipe(
      switchMap((token): Observable<any> => {
        let params = new HttpParams();
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            params = params.set(key, data[key]);
          }
        }
        let headers = new HttpHeaders()
        headers = headers.append('Authorization', `Bearer ${token}`)

        return this.http.post(`${this.apiUrl}booking/confirm/flights/`,data,{
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization','Bearer '+token)
        });
       
      })
    );
  }


getBookings(internal_id:any, booking_id:any) {
  return this.auth.getToken().pipe(
    switchMap((token): Observable<any> => {
    
      let headers = new HttpHeaders()
      headers = headers.append('Authorization', `Bearer ${token}`)
    

      return this.http.get(`${this.apiUrl}bookings?internal_booking_reference=` + internal_id +'&booking_id='+booking_id,{
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Authorization','Bearer '+token)
      });
     
    })
  );
}


getBookingsCard(internal_id:any) {
  return this.auth.getToken().pipe(
    switchMap((token): Observable<any> => {
    
      let headers = new HttpHeaders()
      headers = headers.append('Authorization', `Bearer ${token}`)
    

      return this.http.get(`${this.apiUrl}bookings?internal_booking_reference=` + internal_id,{
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Authorization','Bearer '+token)
      });
     
    })
  );
}



}
