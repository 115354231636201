<div class=" hidden-md hidden-lg ">
    <app-nav ></app-nav>
</div>
<div class="visible-lg visible-md">
  <app-headerhome ></app-headerhome>
</div>
    <div id="container ">
        <div class="bus-booking-page searchFont">
            <div class="theme-hero-area theme-hero-area-full">
                <div class="theme-hero-area-bg-wrap">
                  <div class="theme-hero-area-bg" ></div>
                  <button id="openReloadButton"  [class.is-hidden]="isHidden" data-toggle="modal" data-target="#myModalReload">Open Modal</button>
                  <div class="theme-hero-area-inner-shadow"></div>
                </div>
                <div class="theme-hero-area-body theme-hero-area-body-vert-center">
                  <div class="container">
                    <div class="theme-loading">
                      <div class="row">
                        <div class="col-md-8 col-md-offset-2">
                            
                          <div class="spinner theme-loading-spinner">
                            <div class="spinner-grid-dots">
                                <img src="assets/img/flights/payment_loading.gif">
                            </div>
                          </div>
                          <div class="theme-loading-body">
                            <h2 class="theme-loading-title">Just a moment</h2>
                            <p class="theme-loading-subtitle">
                              <span *ngIf="isSaving" class="destinationHeading text-red">Saving your reservation....</span>
                              <span *ngIf="isWaiting" class="destinationHeading text-red">Processing your payments please wait.....</span>
                              <span *ngIf="!isWaiting" class="destinationHeading text-danger">{{ errorMessage }}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <a class="theme-abs-logo" href="index.html">
                  <img src="img/logo.png" alt="Image Alternative text" title="Image Title"/>
                </a>
              </div>
        </div>
    </div>
    <!-- Modal -->
<div id="myModalReload" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button  id="closeConfirmDialog" type="button" class="close" data-dismiss="modal">&times;</button>
        <br>
        <div class="modal-body">
          <div class="facebook-login__disclaimer ">
            <p class="paragraphRegular">
              <span><i class="fas fa-exclamation-circle"></i></span> Sorry, we're having some issues confirming your payment. Try reloading the page.
            </p>
          </div>
          
          <div class="row">
            <div class="col-md-4 col-md-offset-4">
              <button (click)="backToHome()"  class="theme-search-area-cancel theme-search-area-submit-curved theme-search-area-submit-glow">
                <span >Cancel</span>
              </button>
            </div>
            <div class="col-md-4 ">
              <button (click)="retryConfirmation()"  class="theme-search-area-submit theme-search-area-submit-curved theme-search-area-submit-glow">
                <span >Reload</span>
              </button>
            </div>
          </div>
          
        </div>

      </div>

    </div>
  </div>
</div>
  <!-- Modal -->
<app-footer></app-footer>
