

<app-headerhome  class="visible-lg visible-md"></app-headerhome>
<div id="tophome" class="row  hidden-md hidden-lg" >
  <div class="col-lg-12 bg-grey text-white stuck">
      <div class="input-group _p-10">
        <span class="input-group-btn">
          <button (click)="backHome()" class="theme-search-area-submit theme-search-area-submit-curved buupass-orange-bg" type="button">
              <i class="fas fa-chevron-left"></i>
          </button>
        </span>
          <input (click)="backHome()" class="form-control-search" id="white-placeholder" class="theme-search-area-section-input buupass-pink-light-bg _pl-15"
           type="text"
           type="text"
           placeholder="Search"
           >
      </div>
  </div>
</div>
<div id="top"></div>
<div class=" theme-page-section-buupass-pd buupass-container " >

</div>
<div class="bus-listing-page  searchFont header-displayed "  #floaded>
<div class="buupass-container">
    <div class="page-content row ">
        <div class=" u-ib  col-md-12" id="search-results-wrapper">
            <h2  class="city-labels-lg text-center  visible-lg visible-md">
                    {{dataholderFrom}}
                    →
                    {{dataholderTo}}
                    <span *ngIf="isReturnTicket" class="theme-search-area-title text-center">
                        <svg class="Icon__StyledIcon-sc-1pnzn3g-0 bRGQbR" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <path
                                d="M5.396 6.282l2.922 2.922a.554.554 0 0 1 .182.426c0 .366-.243.609-.609.609L5.9 10.238V14.5a2.1 2.1 0 0 0 1.95 2.095L8 16.6h7.275a.9.9 0 0 1 .113 1.793l-.113.007H8a3.9 3.9 0 0 1-3.895-3.7l-.005-.2v-4.262H2.109a.535.535 0 0 1-.353-.116l-.073-.066a.588.588 0 0 1 0-.852l2.921-2.922c.244-.243.609-.243.792 0zm10.6-.682a3.9 3.9 0 0 1 3.894 3.7l.005.2v4.262h1.992c.146 0 .253.038.352.116l.074.066a.588.588 0 0 1 0 .852l-2.922 2.922c-.243.243-.609.243-.791 0l-2.923-2.922a.554.554 0 0 1-.182-.426c0-.366.244-.609.61-.609l1.99.001V9.5a2.1 2.1 0 0 0-1.95-2.095l-.15-.005H8.72a.9.9 0 0 1-.113-1.793L8.72 5.6h7.275z">
                            </path>
                         </svg>
                        Return Ticket
                        </span>
            </h2>
            <h2 class="city-labels-lg text-center  hidden-md hidden-lg _pt-30">
                    {{fromMobilePlaceholder}} <small>({{this.pickUpInit}})</small>
                    →
                    {{toMobilePlaceholder}} <small>({{this.dropOffInit}})</small>
                    <span *ngIf="isReturnTicket" class="theme-search-area-title text-center">
                        <svg class="Icon__StyledIcon-sc-1pnzn3g-0 bRGQbR" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                            <path
                                d="M5.396 6.282l2.922 2.922a.554.554 0 0 1 .182.426c0 .366-.243.609-.609.609L5.9 10.238V14.5a2.1 2.1 0 0 0 1.95 2.095L8 16.6h7.275a.9.9 0 0 1 .113 1.793l-.113.007H8a3.9 3.9 0 0 1-3.895-3.7l-.005-.2v-4.262H2.109a.535.535 0 0 1-.353-.116l-.073-.066a.588.588 0 0 1 0-.852l2.921-2.922c.244-.243.609-.243.792 0zm10.6-.682a3.9 3.9 0 0 1 3.894 3.7l.005.2v4.262h1.992c.146 0 .253.038.352.116l.074.066a.588.588 0 0 1 0 .852l-2.922 2.922c-.243.243-.609.243-.791 0l-2.923-2.922a.554.554 0 0 1-.182-.426c0-.366.244-.609.61-.609l1.99.001V9.5a2.1 2.1 0 0 0-1.95-2.095l-.15-.005H8.72a.9.9 0 0 1-.113-1.793L8.72 5.6h7.275z">
                            </path>
                         </svg>
                        Return Ticket
                        </span>

            </h2>
            <hr class="hr-sty">
            
        <div  class="row row-col-static" id="sticky-parent" data-gutter="20">
         
          <div class="col-md-10 ">
              <div class="theme-search-results-sort-select _mob-h">
              <ul class="dates ">
                      <li class="prev-container">
                          <a *ngIf="thisYesterdayDate < yesterday" class="prev"  (click)="changeDate(beforeyesterday)" >
                          </a>
                          <a [class.disabled]="true" *ngIf="thisYesterdayDate >= yesterday" > ‹</a>
                        </li>
                  <li class="yesterday-container">
                      <a *ngIf="thisYesterdayDate < yesterday"  class="yesterday" (click)="changeDate(yesterday)">
                          <strong>
                                  {{yesterday  | date:'EEEE, d MMM'}} 
                        </strong></a>
                        <a [class.disabled]="true" *ngIf="thisYesterdayDate >= yesterday" > {{yesterday  | date:'EEEE, d MMM'}}</a>
                  </li>
                  <li class="active-container"><a class="active" [routerLink]="[]"  (click)="changeDate(travelDateInit)" >
                          <strong>
                                  {{travelDateInit  | date:'EEEE, d MMM'}}
                          </strong></a></li>
                  <li class="tomorrow-container"><a class="tomorrow" [routerLink]="[]" (click)="changeDate(tomorrow)">
                          <strong>
                                  {{tomorrow  | date:'EEEE, d MMM'}}
                          </strong></a></li>
                  <li class="next-container"><a class="next" [routerLink]="[]" (click)="changeDate(aftertomorrow)">
                          ›
                      </a></li>
              </ul>
            </div>
            <app-results-loader *ngIf="isResultsLoading"></app-results-loader>
            <app-nobuses *ngIf="availableFlights.length < 1 && !isResultsLoading "></app-nobuses>
         
            <div *ngIf="!isResultsLoading">
              <!-- implementation of mobile -->
             <div >
               <div   *ngFor="let result of availableFlights;let i = index; trackBy: trackByBooking" class="theme-search-results search-result">
                 <div  class="theme-search-results-item u-box sr-row">
                  <div class="theme-search-results-item theme-search-results-item-">
                    <!-- implementation of mobile -->
                    <div class="theme-search-results search-result _desk-h">
                    <div class="theme-search-results-item theme-search-results-item-rounded theme-search-results-item-">
                       <div class="theme-search-results-item-preview ">
                        
                         <div  class="transition-all _ml-10" >
                          <div *ngFor="let seg of result.slices;let i = index;">
                          <div class=" row">
                            <ul class=" theme-search-results-item-flight-details-schedule-features">
                              <li><img height="30px" src="{{logoUrl+seg.segments[0].marketing_carrier.iata_code+'.png'}}"></li>

                              <li *ngIf="result.slices.length > 1"><small><b>{{ i == 1 ? 'Return' : 'Onward' }} .</b></small></li>
                              <li>{{seg.segments[0].marketing_carrier.flight_number}} {{seg.segments[0].marketing_carrier.iata_code}}</li>
                              <li>{{seg.segments[0].marketing_carrier.name}}</li>
                            </ul>
                          </div>
                           <div class="">
                             <div class="row ">
                               <div class="col-md-3">
                                 <div class="theme-search-results-item-flight-details-schedule-destination _mt-10">
                                   <div class="theme-search-results-item-flight-details-schedule-destination-item _mr-40">
                                     <p class="theme-search-results-item-flight-section-meta-time">
                                      {{seg.origin.city_name}}
                                    </p>
                                    <p class="theme-search-results-item-flight-section-meta-time _f-w-500">
                                      <small>{{seg.departing_day.split("T")[1] | timeFormart }}</small>
                                    </p>
                                   </div>
                                   <div class="theme-search-results-item-flight-details-schedule-destination-separator ">
                                     <div class="arrow-container text-center">
                                      <span class="long-arrow">→</span>
                                      <div class="d-ration">{{ seg.duration }}</div>
                                     </div>
                                   </div>
                                   <div class="theme-search-results-item-flight-details-schedule-destination-item _ml-40">
                                    <p class="theme-search-results-item-flight-section-meta-time">{{seg.destination.city_name}}</p>
                                     <p class="theme-search-results-item-flight-section-meta-time _f-w-500">{{seg.arriving_day.split("T")[1] | timeFormart }}</p>
                                   </div>
                                 </div>
                                 
                               </div>
                             </div>
                           </div>
                          </div>
                          <div>
                            <div class="theme-search-results-item-price _ml-20">
                              <div class="theme-search-results-item-price ">
                                <p  class="theme-search-results-item-price-tag"><small>{{result.total_currency}}</small> {{result.total_amount}}</p>
                              </div>
                              <button (click)="getFlightDetails(result)"  mat-flat-button color="accent" class="pull-right reserve-btn">
                               <span *ngIf="!loadingCheckFlights">Select</span>
                                <span *ngIf="loadingCheckFlights">Processing...</span>
                             </button>
                              <div class="theme-search-results-item-flight-section-meta pull-left ">
                               <a class="collapsed text-light" href="#searchResultsItem-{{i}}" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="searchResultsItem-{{i}}">Flight Detail<i class="fas fa-angle-down"></i></a>
   
                             </div>
                            </div>
                          </div>
                         </div>
                        
                       </div>
                     </div> 
                   </div>
                <!-- implementation of mobile -->
                    <div class="theme-search-results-item-preview _mob-h">
                      <div class="row" data-gutter="20">
                        <div class="col-md-10 ">
                          <a class="theme-search-results-item-mask-link collapsed" href="#searchResultsItem-{{i}}" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="searchResultsItem-{{i}}"></a>
                          <div class="theme-search-results-item-flight-sections">
                            <div class="theme-search-results-item-flight-section">
                              <div class="row">
                                <div *ngFor="let seg of result.slices;let i = index;">
                                  <div [ngClass]="result.slices.length > 1 ? 'col-md-6' : 'col-md-12'" >
                                    <div [ngClass]="i == 1 ? 'row row-no-gutter row-eq-height theme-search-results-item-flight-details-schedule-list' : 'row row-no-gutter row-eq-height'">
                                      <div class="col-md-2 ">
                                        <p ><small><b  *ngIf="result.slices.length > 1">{{ i == 1 ? 'Return' : 'Onward' }} .</b> {{seg.segments[0].marketing_carrier.name}} </small></p>
                                        <div class="theme-search-results-item-flight-section-airline-logo-wrap">
                                            <img height="35px"  class="" src="{{logoUrl+seg.segments[0].marketing_carrier.iata_code+'.png'}}" alt="{{seg.segments[0].marketing_carrier.name}}" title="{{seg.segments[0].marketing_carrier.name}}">
                                        </div>
                                      </div>
                                      <div class="col-md-10 ">
                                        <div class="theme-search-results-item-flight-section-item">
                                          <div class="row">
                                            <div class="col-md-3 ">
                                              <div class="theme-search-results-item-flight-section-meta">
                                                <p class="airport-code">{{seg.origin.city_name}} ({{seg.origin.iata_code}})</p>
                                              
                                                <p class="theme-search-results-item-flight-section-meta-time">{{seg.departing_day.split("T")[1] | timeFormart}} 
                                                </p>
                                                <p class="theme-search-results-item-flight-section-meta-date">{{seg.departing_day.split("T")[0]}}</p>
                                                <!-- <p class="flight-city-name">CityName</p> -->
                                                
                                              </div>
                                            </div>
                                            <div class="col-md-6 ">
                                              <div class="theme-search-results-item-flight-section-path">
                                                <div class="theme-search-results-item-flight-section-path-fly-time">
                                                  <p>{{ seg.duration }}</p>
                                                </div>
                                                <div class="theme-search-results-item-flight-section-path-line"></div>
                                                <div class="theme-search-results-item-flight-section-path-line-start">
                                                  <i class="fa fa-plane theme-search-results-item-flight-section-path-icon"></i>
                                                  <div class="theme-search-results-item-flight-section-path-line-dot"></div>
                                                  <p class="theme-search-results-item-flight-section-path-line-title">{{seg.origin.iata_code}}</p>
      
                                                </div>
                                                <div class="theme-search-results-item-flight-section-path-line-middle">
                                                  <i class="fa fa-plane theme-search-results-item-flight-section-path-icon"></i>
                                                  <div class="theme-search-results-item-flight-section-path-line-dot"></div>
                                                  <div class="theme-search-results-item-flight-section-path-line-title">{{seg.stops}}</div>
                                                </div>
                                                <div class="theme-search-results-item-flight-section-path-line-end">
                                                  <i class="fa fa-plane theme-search-results-item-flight-section-path-icon"></i>
                                                  <div class="theme-search-results-item-flight-section-path-line-dot"></div>
                                                  <div class="theme-search-results-item-flight-section-path-line-title">{{seg.destination.iata_code}}</div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="col-md-3 ">
                                              <div class="theme-search-results-item-flight-section-meta">
                                                <p class="airport-code">{{seg.destination.city_name}} ({{seg.destination.iata_code}})</p>
                                                <p class="theme-search-results-item-flight-section-meta-time"> {{seg.arriving_day.split("T")[1] | timeFormart }}
                                                </p>
                                                <p class="theme-search-results-item-flight-section-meta-date">{{seg.arriving_day.split("T")[0]}}</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                

                               
                              </div>
                              <div class="theme-search-results-item-flight-section-meta pull-right">
                                <a href="#searchResultsItem-{{i}}" class="text-light"><small>Flight Detail  <i class="fas fa-angle-down"></i></small></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2 ">
                          <div class="theme-search-results-item-book">
                            <div class="theme-search-results-item-price">
                              
                              <p class="theme-search-results-item-price-tag">{{result.total_currency}} {{result.total_amount}}</p>
                            </div>
                            <button (click)="getFlightDetails(result)" class="btn reserve" >
                              <span *ngIf="!loadingCheckFlights">Select</span>
                              <span *ngIf="loadingCheckFlights">Processing...</span>
                            </button>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="collapse theme-search-results-item-collapse" id="searchResultsItem-{{i}}">
                      <div class="theme-search-results-item-extend">
                        <a class="theme-search-results-item-extend-close" href="#searchResultsItem-{{i}}" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="searchResultsItem-2">&#10005;</a>
                        <div class="theme-search-results-item-extend-inner">
                          <div class="theme-search-results-item-flight-detail-items">
                            <div class="theme-search-results-item-flight-details">
                              <div *ngFor="let seg of result.slices;let i = index;">
                                <div class="row">
                                  <div class="col-md-10">
                                    <h6 class="theme-search-results-item-flight-details-info-date">{{seg.origin.city_name}} &rarr; {{seg.destination.city_name}}</h6>
                                    <p>
                                      <span >{{seg.departing_day.split("T")[0]}}</span>.
                                      <span class="theme-search-results-item-flight-details-info-stops">{{seg.stops}}</span> . 
                                      <span class="theme-search-results-item-flight-details-info-fly-time"> {{ seg.duration }}</span></p>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-10 ">
                                    <div class="theme-search-results-item-flight-details-schedule">
                                      <ul class="theme-search-results-item-flight-details-schedule-list" *ngFor="let segment of seg.segments">
                                        <li>
                                          <div class="theme-search-results-item-flight-details-schedule-dots"></div>
  
                                          <div class="row">
                                            <div class="col-md-8">
                                              <i class="fa fa-plane theme-search-results-item-flight-details-schedule-icon"></i>
                                              <ul class="theme-search-results-item-flight-details-schedule-features">
                                                <li><img height="30px" src="{{logoUrl+seg.segments[0].marketing_carrier.iata_code+'.png'}}"></li>
                                                <li>{{segment.marketing_carrier.flight_number}} {{segment.marketing_carrier.iata_code}}</li>
                                                <li>{{segment.marketing_carrier.name}}</li>
                                              </ul>
                                              <p class="theme-search-results-item-flight-details-schedule-date">{{segment.departing_at.split("T")[0]}}</p>
                                              <div class="theme-search-results-item-flight-details-schedule-time">
                                                <span class="theme-search-results-item-flight-details-schedule-time-item">{{segment.departing_at.split("T")[1] | timeFormart }}
                                                </span>
                                                <span class="theme-search-results-item-flight-details-schedule-time-separator">&mdash;</span>
                                                <span class="theme-search-results-item-flight-details-schedule-time-item">{{segment.arriving_at.split("T")[1] | timeFormart }}
                                                </span>
                                              </div>
                                              <p class="theme-search-results-item-flight-details-schedule-fly-time">{{segment.duration}}</p>
                                              <div class="theme-search-results-item-flight-details-schedule-destination">
                                                <div class="theme-search-results-item-flight-details-schedule-destination-item">
                                                  <p class="theme-search-results-item-flight-details-schedule-destination-title">
                                                    {{segment.origin.name}}
                                                  </p>
                                                  <p class="theme-search-results-item-flight-details-schedule-destination-city">{{segment.origin.city_name}}</p>
                                                </div>
                                                <div class="theme-search-results-item-flight-details-schedule-destination-separator">
                                                  <span>&rarr;</span>
                                                </div>
                                                <div class="theme-search-results-item-flight-details-schedule-destination-item">
                                                  <p class="theme-search-results-item-flight-details-schedule-destination-title">
                                                    {{segment.destination.name}}
                                                  </p>
                                                  <p class="theme-search-results-item-flight-details-schedule-destination-city">{{segment.destination.city_name}}</p>
                                                </div>
                                              </div>
                                              
                                            </div>
                                          
                                          </div>
                                        </li>
                                        <li *ngIf="segment.waiting !=0">
                                          <!-- <i class="fa fa-exchange theme-search-results-item-flight-details-schedule-icon"></i> -->
                                          <i class="fas fa-exchange-alt theme-search-results-item-flight-details-schedule-icon exchange-flight-icon"></i>
                                          <div class="theme-search-results-item-flight-details-schedule-dots"></div>
                                          <p class="theme-search-results-item-flight-details-schedule-fly-time">Check transit visa requirements <a href="https://www.iatatravelcentre.com/passport-visa-health-travel-document-requirements.htm">here</a></p>
                                          <p class="theme-search-results-item-flight-details-schedule-transfer _mb-10">Change planes in {{segment.destination.city_name}} ({{segment.destination.iata_code}}) . <b>{{segment.waiting}}</b> Layover</p>
                                        </li>
                                      
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 </div>
                
               </div>
             </div>
             
       
           </div>

            <!-- <a class="btn _tt-uc _fs-sm _mt-10 btn-white btn-block btn-lg" href="#">Load More Results</a> -->
          </div>
          <div class="col-md-1-5 ">
            <div class="sticky-col _mob-h" style="">
              <div class="theme-ad _mb-20">
                <a class="theme-ad-link" href="#"></a>
                <p class="theme-ad-sign">Advertisement</p>
                <img class="theme-ad-img" src="assets/img/offers/plane.jpeg" alt="Image Alternative text" title="Image Title">
              </div>
              <div class="theme-ad">
                <a class="theme-ad-link" href="#"></a>
                <p class="theme-ad-sign">Advertisement</p>
                <img class="theme-ad-img" src="assets/img/images/payments.png" alt="Image Alternative text" title="Image Title">
              </div>
            </div>
          </div> 
        </div>
       </div>


</div>
</div>

</div>
      <div class="theme-page-section margin-grey theme-page-section-white">
        <div class="container">
          <div class="row row-col-static" id="sticky-parent">

            <div class="col-md-11">

              <div class="theme-search-results _mb-10">

              </div>
            </div>
          </div>
        </div>
      </div>

<app-footer></app-footer>
