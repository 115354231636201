import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ItineraryServiceService, ConfirmService, DataService } from '../services/index'
import { Error } from '../models/index'
import { Base64 } from 'js-base64';
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {
  sub:any;
  confirmData:any;
  res:any;
  isError:boolean;
  errorMessage:any;
  successMessage:any;
  isWaiting:boolean;
  isSaving:boolean
  waitingMessage:any;
  api_error:Error;
  isHidden:boolean = true;
  passData:any;
  started=0;
  int1:any;
  int2:any;
  int3:any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: ConfirmService,
    private itenaryservice:ItineraryServiceService,
    private dataService: DataService
  ) { }
  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      let data= params['id'];
      this.confirmData=JSON.parse(atob(data));
      var self = this;
      let bdata = this.confirmData.data
      this.isWaiting=true;
      self.getBookingStatus(bdata.internal_booking_reference,bdata.id)
    });
  }

  initiateSTKPush(data:any,internal,id){
    let self = this
    this.itenaryservice.initiateStk(data).subscribe(
      data  => {
        this.res=data;
        setTimeout(function () {
          self.getBookingStatus(internal,id)
        }, 8000);
      },
      error  => {
        setTimeout(function () {
          self.getBookingStatus(internal,id)
        }, 8000);
      }
    );

  }

  getBookingStatus(internal_id,booking_id){
    this.isWaiting=true;
    this.isSaving=false;
    this.waitingMessage="Processing your payments please wait.....";
    this.itenaryservice.getBookings(internal_id,booking_id).subscribe(
      data  => {
        this.res=data.data[0];
        var self = this;
        if(this.res.status =='success'){
          this.isWaiting=false;
          this.successMessage="Payment processed successfully";
          let f=btoa(JSON.stringify(this.confirmData))
          clearInterval(self.int1);
          clearInterval(self.int2);
          clearInterval(self.int3);
          this.router.navigate(['/booking-successful', f])
         
        }else if(this.res.status == "hold") {
          let paymentStatus = false;
        
          paymentStatus = this.res.payments && this.res.payments.payment_code ? true : false;

          if(paymentStatus){
            this.isWaiting=false;
            this.successMessage="Payment processed successfully";
            let f=btoa(JSON.stringify(this.confirmData))
            clearInterval(self.int1);
            clearInterval(self.int2);
            clearInterval(self.int3);
            this.router.navigate(['/booking-successful', f])
          }else{
            this.isWaiting=true;
                this.waitingMessage="Processing your payments please wait.....";
                  var self = this;
                            setTimeout(function () {
                              if(self.started==0){
                                 self.started=1
                                 var i = 1000;
                                 self.int2 = setInterval(function () {
                                  self.getBookingStatus(internal_id,booking_id)
                                  i += 1000;
                                  if (i >= 25000){
                                    clearInterval(self.int1);
                                    clearInterval(self.int2);
                                    clearInterval(self.int3);
                                    document.getElementById("openReloadButton").click();
                                    // self.router.navigate(['/index.html']);
                                  } ;
                                }, 10000);
                              }
                          }, 1000);
          }
        } else if(this.res.status == 'cancelled' ){
          var self = this;
          this.isWaiting=false;
          this.isError=true;
          this.errorMessage="Sorry your payment request was canceled. Please try again";
          
          let bdata=this.confirmData
          let fdata={
            "flight_data":this.confirmData,
            "isError":true,
            "errorMessage":'cancelled'
          }
               clearInterval(self.int1);
                clearInterval(self.int2);
                clearInterval(self.int3);
          let pay=Base64.encode(JSON.stringify(fdata));
          let paymessage=btoa("Opps sory mate!");
          this.dataService.changePaymentMessage(pay);
          this.router.navigate(['/payments', paymessage])
          
          if (this.res.status == "hold"){
            

              setTimeout(function () {
                clearInterval(self.int1);
                clearInterval(self.int2);
                clearInterval(self.int3);
                document.getElementById("openReloadButton").click();
                // self.router.navigate(['/index.html']);
              }, 10000);

            }
                    setTimeout(function () {
                      if(self.started==0){
                         self.started=1
                         var i = 1000;
                          self.int1 = setInterval(function () {
                          self.getBookingStatus(internal_id,booking_id)
                          i += 1000;
                          if (i >= 25000){
                            clearInterval(self.int1);
                            clearInterval(self.int2);
                            clearInterval(self.int3);
                            document.getElementById("openReloadButton").click();
                            // this.router.navigate(['/index.html']);
                          } ;
                        }, 10000);
                      }
                  }, 1000);
        }else{

                this.isWaiting=true;
                this.waitingMessage="Processing your payments please wait.....";
                  var self = this;
                            setTimeout(function () {
                              if(self.started==0){
                                 self.started=1
                                 var i = 1000;
                                 self.int2 = setInterval(function () {
                                  self.getBookingStatus(internal_id,booking_id)
                                  i += 1000;
                                  if (i >= 25000){
                                    clearInterval(self.int1);
                                    clearInterval(self.int2);
                                    clearInterval(self.int3);
                                    document.getElementById("openReloadButton").click();
                                    // self.router.navigate(['/index.html']);
                                  } ;
                                }, 10000);
                              }
                          }, 1000);
        }


      },
      error  => {
        this.isError=true;
        this.isWaiting=true;
        this.waitingMessage="Processing your payments please wait.....";
        this.api_error=new Error().deserialize(error.error.errors)
        var self = this;
                  setTimeout(function () {
                    if(self.started==0){
                       self.started=1
                       var i = 1000;
                        self.int3 = setInterval(function () {
                        self.getBookingStatus(internal_id,booking_id)
                        i += 1000;
                        if (i >= 25000){
                            clearInterval(self.int1);
                            clearInterval(self.int2);
                            clearInterval(self.int3);
                            self.router.navigate(['/index.html']);
                        } ;
                      }, 10000);
                    }
                }, 1000);

      }
    );
  }

  backToHome(){
    document.getElementById("closeConfirmDialog").click();
    clearInterval(this.int1);
    clearInterval(this.int2);
    clearInterval(this.int3);
    this.router.navigate(['/index.html']);
  }

  retryConfirmation(){
    document.getElementById("closeConfirmDialog").click();
    clearInterval(this.int1);
    clearInterval(this.int2);
    clearInterval(this.int3);
    setTimeout(function () {
      this.confirmMpesa(this.confirmData);
    }, 3000);

  }
  confirmMpesa(dataReceived:any){

    this.isWaiting=true;
    this.isSaving=false;
    this.waitingMessage="Processing your payments please wait.....";
        let pay_id ='';
        pay_id = dataReceived.data.booking_id;
        let transaction_id = dataReceived.data.transaction_id;
        
        let confirm_data = {
          "api_agent":"kiwi",
          "booking_id":pay_id,
          "transaction_id":transaction_id,
          "booking_test":0//0 if its live
        }

    this.service.confirmV2(pay_id,confirm_data).subscribe(
      response => {
        this.res=response;
        var self = this;
        
        if(this.res.data.status =='paid'){
          this.isWaiting=false;
          this.successMessage="Payment processed successfully";
          let f=btoa(JSON.stringify(this.res))
          clearInterval(self.int1);
          clearInterval(self.int2);
          clearInterval(self.int3);
          this.router.navigate(['/booking-successful', f])
         
         

        }else if(this.res.data.status == 'cancelled' || this.res.message.includes('cancelled') ){
          var self = this;
          this.isWaiting=false;
          this.isError=true;
          this.errorMessage="Sorry your payment request was canceled. Please try again";
          
          let bdata=this.confirmData.booking_data
          let fdata={
            "flight_data":this.confirmData.booking_data.flight_data,
            "search_data":this.confirmData.booking_data.search_data,
            "isError":true,
            "errorMessage":'cancelled'
          }
               clearInterval(self.int1);
                clearInterval(self.int2);
                clearInterval(self.int3);
          let pay=Base64.encode(JSON.stringify(fdata));
          let paymessage=btoa("Opps sory mate!");
          this.dataService.changePaymentMessage(pay);
          this.router.navigate(['/payments', paymessage])
          
          if (this.res.reason == "validation"){

              setTimeout(function () {
                clearInterval(self.int1);
                clearInterval(self.int2);
                clearInterval(self.int3);
                document.getElementById("openReloadButton").click();
                // self.router.navigate(['/index.html']);
              }, 10000);

            }
         
                    setTimeout(function () {
                      if(self.started==0){
                         self.started=1
                         var i = 1000;
                          self.int1 = setInterval(function () {
                          self.confirmMpesa(dataReceived);
                          i += 1000;
                          if (i >= 25000){
                            clearInterval(self.int1);
                            clearInterval(self.int2);
                            clearInterval(self.int3);
                            document.getElementById("openReloadButton").click();
                            // this.router.navigate(['/index.html']);
                          } ;
                        }, 10000);
                      }
                  }, 1000);
        }else{

                this.isWaiting=true;
                this.waitingMessage="Processing your payments please wait.....";
                  var self = this;
                            setTimeout(function () {
                              if(self.started==0){
                                 self.started=1
                                 var i = 1000;
                                 self.int2 = setInterval(function () {
                                  self.confirmMpesa(dataReceived);
                                  i += 1000;
                                  if (i >= 25000){
                                    clearInterval(self.int1);
                                    clearInterval(self.int2);
                                    clearInterval(self.int3);
                                    document.getElementById("openReloadButton").click();
                                    // self.router.navigate(['/index.html']);
                                  } ;
                                }, 10000);
                              }
                          }, 1000);
        }
      },
      error => {
        this.isError=true;
        this.isWaiting=true;
        this.waitingMessage="Processing your payments please wait.....";
        this.api_error=new Error().deserialize(error.error.errors)
        var self = this;
                  setTimeout(function () {
                    if(self.started==0){
                       self.started=1
                       var i = 1000;
                        self.int3 = setInterval(function () {
                        self.confirmMpesa(dataReceived);
                        i += 1000;
                        if (i >= 25000){
                            clearInterval(self.int1);
                            clearInterval(self.int2);
                            clearInterval(self.int3);
                            self.router.navigate(['/index.html']);
                        } ;
                      }, 10000);
                    }
                }, 1000);
      }
    );



  }

}
