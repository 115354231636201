<div class=" hidden-md hidden-lg ">
  <app-nav ></app-nav>
</div>
<div class="visible-lg visible-md">
<app-headerhome ></app-headerhome>
</div>
<div  class="theme-page-section theme-page-section-lg _mt-90">
    <div  class="container">
      <div class="row row-col-static row-col-mob-gap" id="sticky-parent" data-gutter="60" style="position: relative;">
        <div class="col-md-8 ">
          <div class="theme-payment-page-sections">
            <div class="theme-payment-page-sections-item">
              <div class="theme-search-results-item theme-payment-page-item-thumb">
                <div class="row" data-gutter="20">
                  <div class="col-md-9 ">
                    <h5 class="theme-search-results-item-title">{{bookingData.slices[0].origin.city_name}}, {{bookingData.slices[0].origin.iata_code}} &nbsp;&rarr;&nbsp; {{bookingData.slices[0].destination.city_name}}, {{bookingData.slices[0].destination.iata_code}}</h5>
                    <p class="theme-search-results-item-flight-payment-info">{{bookingData.slices[0].stops}}, Economy, 
                      {{ counts.adult > 1 ? counts.adult + ' Adults' : (counts.adult === 1 ? counts.adult + ' Adult' : '') }}
                      {{ counts.child > 1 ? counts.child + ' Children' : (counts.child === 1 ? counts.child + ' Child' : '') }}
                      {{ counts.infant > 1 ? counts.infant + ' Infants' : (counts.infant === 1 ? counts.infant + ' Infant' : '') }}

                    </p>
                    <ul class="theme-search-results-item-flight-payment-dates">
                      <li>Depart: {{bookingData.slices[0].departing_day.split("T")[0] }}</li>
                      <li *ngIf="bookingData.slices.length > 1">Return: {{bookingData.slices[1].departing_day.split("T")[0]}}</li>
                    </ul>
                    <a class="theme-search-results-item-flight-payment-details-link" href="#FlightPaymentDetails" data-toggle="collapse" aria-expanded="false" aria-controls="FlightPaymentDetails">Flight Details &nbsp;
                      <i class="fa fa-angle-down"></i>
                    </a>
                    <div class="collapse _pt-20" id="FlightPaymentDetails">
                      <div class="theme-search-results-item-flight-detail-items">
                        <div class="theme-search-results-item-flight-details">
                         
                          <div class="row">
                            <div *ngFor="let seg of bookingData.slices;let i = index;">
                            <div class="col-md-12 ">
                              <div class="theme-search-results-item-flight-details-schedule">
                                <ul class="theme-search-results-item-flight-details-schedule-list" *ngFor="let segment of seg.segments">
                                  <li>
                                    <div class="theme-search-results-item-flight-details-schedule-dots"></div>
                                    <div class="row">
                                      <div class="col-md-8">
                                        <i class="fa fa-plane theme-search-results-item-flight-details-schedule-icon"></i>
                                        <ul class="theme-search-results-item-flight-details-schedule-features">
                                          <li><img height="30px" src="{{segment.marketing_carrier.logo}}"></li>
                                          <li *ngIf="bookingData.slices.length > 1"><b  >{{ i == 1 ? 'Return' : 'Onward' }} .</b></li>
                                          <li>{{segment.marketing_carrier.flight_number}} {{segment.marketing_carrier.iata_code}}</li>
                                          <li>{{segment.marketing_carrier.name}}</li>
                                        </ul>
                                        <p class="theme-search-results-item-flight-details-schedule-date">{{segment.departing_at.split("T")[0]}}</p>
                                        <div class="theme-search-results-item-flight-details-schedule-time">
                                          <span class="theme-search-results-item-flight-details-schedule-time-item">{{segment.departing_at.split("T")[1] | timeFormart }}
                                          </span>
                                          <span class="theme-search-results-item-flight-details-schedule-time-separator">&mdash;</span>
                                          <span class="theme-search-results-item-flight-details-schedule-time-item">{{segment.arriving_at.split("T")[1] | timeFormart }}
                                          </span>
                                        </div>
                                        <p class="theme-search-results-item-flight-details-schedule-fly-time">{{segment.duration}}</p>
                                        <div class="theme-search-results-item-flight-details-schedule-destination">
                                          <div class="theme-search-results-item-flight-details-schedule-destination-item">
                                            <p class="theme-search-results-item-flight-details-schedule-destination-title">
                                              {{segment.origin.name}}
                                            </p>
                                            <p class="theme-search-results-item-flight-details-schedule-destination-city">{{segment.origin.city_name}}</p>
                                          </div>
                                          <div class="theme-search-results-item-flight-details-schedule-destination-separator">
                                            <span>&rarr;</span>
                                          </div>
                                          <div class="theme-search-results-item-flight-details-schedule-destination-item">
                                            <p class="theme-search-results-item-flight-details-schedule-destination-title">
                                              {{segment.destination.name}}
                                            </p>
                                            <p class="theme-search-results-item-flight-details-schedule-destination-city">{{segment.destination.city_name}}</p>
                                          </div>
                                        </div>
                                        
                                      </div>
                                      <div class="col-md-4 _mob-h">
                                        <div class="row">
                                          <div class="col-md-4">
                                            <h6>Baggage</h6>
                                            <p class="theme-search-results-item-flight-details-schedule-destination-title">Per Traveller</p>
                                          </div>
                                          <div class="col-md-4">
                                            <h6>Cabin</h6>
                                            <p class="theme-search-results-item-flight-details-schedule-destination-title">
                                              <span *ngIf="segment?.passengers?.[0]?.baggages?.length">
                                              <span *ngFor="let bg of segment.passengers[0].baggages">
                                                <span *ngIf="bg.type == 'carry_on'">
                                                  {{ bg.quantity }} Unit(s)
                                                </span>
                                              </span>
                                              </span>
                                            </p>
                                          </div>
                                          <div class="col-md-4">
                                            <h6>Check-in</h6>
                                            <p class="theme-search-results-item-flight-details-schedule-destination-title">
                                              <span *ngIf="segment?.passengers?.[0]?.baggages?.length">
                                              <span *ngFor="let bg of segment.passengers[0].baggages">
                                                <span *ngIf="bg.type == 'checked'">
                                                  {{ bg.quantity }} Unit(s)
                                                </span>
                                              </span>
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li *ngIf="segment.waiting !=0">
                                    <!-- <i class="fa fa-exchange theme-search-results-item-flight-details-schedule-icon"></i> -->
                                    <i class="fas fa-exchange-alt theme-search-results-item-flight-details-schedule-icon exchange-flight-icon"></i>
                                    <div class="theme-search-results-item-flight-details-schedule-dots"></div>
                                    <p class="theme-search-results-item-flight-details-schedule-fly-time">Check transit visa requirements <a href="https://www.iatatravelcentre.com/passport-visa-health-travel-document-requirements.htm">here</a></p>
                                    <p class="theme-search-results-item-flight-details-schedule-transfer _mb-10">Change planes in {{segment.destination.city_name}} ({{segment.destination.iata_code}}) . <b>{{segment.waiting}}</b> Layover</p>
                                  </li>
                                
                                </ul>
                              </div>
                            </div>
                          </div>
                          </div>
                        </div>
                   
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 ">
                    <div class="theme-search-results-item-img-wrap">
                      <img class="theme-search-results-item-img _mob-h" src="{{logoUrl+bookingData.slices[0].segments[0].marketing_carrier.iata_code+'.png'}}" alt="{{bookingData.slices[0].segments[0].marketing_carrier.name}}" title="{{bookingData.slices[0].segments[0].marketing_carrier.name}}"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="isError" class="alert alert-danger" role="alert">
              <h5>We couldn't process your payment. Please try the following:</h5>
              <ul style="list-style-type:disc;">
                  <li>Double-check your mobile number. </li>
                  <li>Make sure you have enough money in your mpesa</li>
                  <li>Try again later</li>
                </ul>
              
            </div>
            <div class="theme-payment-page-sections-item">
              <form class="makeFlex" id="ngFormPayment" [formGroup]="paymentForm" >
                <div class="theme-payment-page-form">
                  <h3 class="theme-payment-page-form-title pull-right p-passenger">Primary passenger (payee)</h3>
                    <div *ngFor="let seat of groupedPassengers.adult;let i = index;">
                      <div class="theme-payment-page-sections-item-passenger">
                        <div class="row row-col-gap" >
                          <h3 class="theme-payment-page-form-title"><b>Passenger {{i+1}}  details </b> </h3>
                          <div class="row  _mb-10 _ml-0 ">
                            <div class="col-md-6 ">
                              <label>Title(Mr/Mrs/Miss..)</label>
                              <div class="theme-payment-page-form-item form-group">
                                <i class="fas fa-angle-down"></i>
                                <select class="form-control" 
                                    name="titlea{{i}}"
                                    id="titlea{{i}}"
                                    tabindex="0"
                                    formControlName="titlea{{i}}">
                                  <option value="">Select title</option>
                                  <option value="mr">Mr</option>
                                  <option value="ms">Miss</option>
                                  <option value="mrs">Mrs</option>
                                </select>
                                <small class="invalid-select" *ngIf="paymentForm.get('titlea'+i).touched && paymentForm.get('titlea'+i).hasError('required')">Your gender is required!</small>
                              </div>
                            </div>
                          </div>
                          
                          <div class="col-md-6 ">
                            <div class="theme-payment-page-form-item form-group">
                              <label>Name</label>
                              <input class="form-control" type="text" placeholder="Name"
                                  name="fnamea{{i}}"
                                  id="fnamea{{i}}"
                                  tabindex="0"
                                  formControlName="fnamea{{i}}">
                                  <small class="invalid-select" *ngIf="paymentForm.get('fnamea'+i).touched && paymentForm.get('fnamea'+i).hasError('pattern')">Your Name should not contain numbers or spaces!</small>
                                  <small class="invalid-select" *ngIf="paymentForm.get('fnamea'+i).touched && paymentForm.get('fnamea'+i).hasError('required')">Your Name is required!</small>     
                            </div>
                          </div>
                          <div class="col-md-6 ">
                            <div class="theme-payment-page-form-item form-group">
                              <label>Last Name</label>
                              <input class="form-control" type="text" placeholder="Last Name" 
                                  name="lnamea{{i}}"
                                  id="lnamea{{i}}"
                                  tabindex="0"
                                  formControlName="lnamea{{i}}">
                                  <small class="invalid-select" *ngIf="paymentForm.get('lnamea'+i).touched && paymentForm.get('lnamea'+i).hasError('pattern')">Your last name should not contain numbers or spaces!</small>
                                  <small class="invalid-select" *ngIf="paymentForm.get('lnamea'+i).touched && paymentForm.get('lnamea'+i).hasError('required')">Your last name is required!</small>
                            </div>
                          </div>
                          <div class="row _mt-10"></div>
                          <div class="col-md-6 ">
                            <div class="theme-payment-page-form-item  form-group">
                              <label>Passport or ID number</label>
                              <input class="form-control" type="text" placeholder="Passport/ID" 
                                  name="idnoa{{i}}"
                                  id="idnoa{{i}}"
                                  tabindex="0"
                                  formControlName="idnoa{{i}}">
                                  <small class="invalid-select" *ngIf="paymentForm.get('idnoa'+i).touched && paymentForm.get('idnoa'+i).hasError('minlength')">Your Id/passport should be valid</small>
                                  <small class="invalid-select" *ngIf="paymentForm.get('idnoa'+i).touched && paymentForm.get('idnoa'+i).hasError('required')">Your Id/passport is required!</small>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <label>Nationality</label>
                            <div class="theme-payment-page-form-item form-group">
                              <i class="fas fa-angle-down"></i>
                              <select class="form-control"   
                              name="countrya{{i}}"
                              id="countrya{{i}}"
                              tabindex="0"
                              formControlName="countrya{{i}}">
                                <option value="">Select nationality</option>
                                <option value="KE">Kenya</option>
                                <option value="UG">Uganda</option>
                                <option value="AF">Afghanistan</option>
                                <option value="AX">Aland Islands</option>
                                <option value="AL">Albania</option>
                                <option value="DZ">Algeria</option>
                                <option value="AS">American Samoa</option>
                                <option value="AD">Andorra</option>
                                <option value="AO">Angola</option>
                                <option value="AI">Anguilla</option>
                                <option value="AQ">Antarctica</option>
                                <option value="AG">Antigua and Barbuda</option>
                                <option value="AR">Argentina</option>
                                <option value="AM">Armenia</option>
                                <option value="AW">Aruba</option>
                                <option value="AU">Australia</option>
                                <option value="AT">Austria</option>
                                <option value="AZ">Azerbaijan</option>
                                <option value="BS">Bahamas</option>
                                <option value="BH">Bahrain</option>
                                <option value="BD">Bangladesh</option>
                                <option value="BB">Barbados</option>
                                <option value="BY">Belarus</option>
                                <option value="BE">Belgium</option>
                                <option value="BZ">Belize</option>
                                <option value="BJ">Benin</option>
                                <option value="BM">Bermuda</option>
                                <option value="BT">Bhutan</option>
                                <option value="BO">Bolivia</option>
                                <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                <option value="BA">Bosnia and Herzegovina</option>
                                <option value="BW">Botswana</option>
                                <option value="BV">Bouvet Island</option>
                                <option value="BR">Brazil</option>
                                <option value="IO">British Indian Ocean Territory</option>
                                <option value="BN">Brunei Darussalam</option>
                                <option value="BG">Bulgaria</option>
                                <option value="BF">Burkina Faso</option>
                                <option value="BI">Burundi</option>
                                <option value="KH">Cambodia</option>
                                <option value="CM">Cameroon</option>
                                <option value="CA">Canada</option>
                                <option value="CV">Cape Verde</option>
                                <option value="KY">Cayman Islands</option>
                                <option value="CF">Central African Republic</option>
                                <option value="TD">Chad</option>
                                <option value="CL">Chile</option>
                                <option value="CN">China</option>
                                <option value="CX">Christmas Island</option>
                                <option value="CC">Cocos (Keeling) Islands</option>
                                <option value="CO">Colombia</option>
                                <option value="KM">Comoros</option>
                                <option value="CG">Congo</option>
                                <option value="CD">Congo, Democratic Republic of the Congo</option>
                                <option value="CK">Cook Islands</option>
                                <option value="CR">Costa Rica</option>
                                <option value="CI">Cote D'Ivoire</option>
                                <option value="HR">Croatia</option>
                                <option value="CU">Cuba</option>
                                <option value="CW">Curacao</option>
                                <option value="CY">Cyprus</option>
                                <option value="CZ">Czech Republic</option>
                                <option value="DK">Denmark</option>
                                <option value="DJ">Djibouti</option>
                                <option value="DM">Dominica</option>
                                <option value="DO">Dominican Republic</option>
                                <option value="EC">Ecuador</option>
                                <option value="EG">Egypt</option>
                                <option value="SV">El Salvador</option>
                                <option value="GQ">Equatorial Guinea</option>
                                <option value="ER">Eritrea</option>
                                <option value="EE">Estonia</option>
                                <option value="ET">Ethiopia</option>
                                <option value="FK">Falkland Islands (Malvinas)</option>
                                <option value="FO">Faroe Islands</option>
                                <option value="FJ">Fiji</option>
                                <option value="FI">Finland</option>
                                <option value="FR">France</option>
                                <option value="GF">French Guiana</option>
                                <option value="PF">French Polynesia</option>
                                <option value="TF">French Southern Territories</option>
                                <option value="GA">Gabon</option>
                                <option value="GM">Gambia</option>
                                <option value="GE">Georgia</option>
                                <option value="DE">Germany</option>
                                <option value="GH">Ghana</option>
                                <option value="GI">Gibraltar</option>
                                <option value="GR">Greece</option>
                                <option value="GL">Greenland</option>
                                <option value="GD">Grenada</option>
                                <option value="GP">Guadeloupe</option>
                                <option value="GU">Guam</option>
                                <option value="GT">Guatemala</option>
                                <option value="GG">Guernsey</option>
                                <option value="GN">Guinea</option>
                                <option value="GW">Guinea-Bissau</option>
                                <option value="GY">Guyana</option>
                                <option value="HT">Haiti</option>
                                <option value="HM">Heard Island and Mcdonald Islands</option>
                                <option value="VA">Holy See (Vatican City State)</option>
                                <option value="HN">Honduras</option>
                                <option value="HK">Hong Kong</option>
                                <option value="HU">Hungary</option>
                                <option value="IS">Iceland</option>
                                <option value="IN">India</option>
                                <option value="ID">Indonesia</option>
                                <option value="IR">Iran, Islamic Republic of</option>
                                <option value="IQ">Iraq</option>
                                <option value="IE">Ireland</option>
                                <option value="IM">Isle of Man</option>
                                <option value="IL">Israel</option>
                                <option value="IT">Italy</option>
                                <option value="JM">Jamaica</option>
                                <option value="JP">Japan</option>
                                <option value="JE">Jersey</option>
                                <option value="JO">Jordan</option>
                                <option value="KZ">Kazakhstan</option>
                                <option value="KI">Kiribati</option>
                                <option value="KP">Korea, Democratic People's Republic of</option>
                                <option value="KR">Korea, Republic of</option>
                                <option value="XK">Kosovo</option>
                                <option value="KW">Kuwait</option>
                                <option value="KG">Kyrgyzstan</option>
                                <option value="LA">Lao People's Democratic Republic</option>
                                <option value="LV">Latvia</option>
                                <option value="LB">Lebanon</option>
                                <option value="LS">Lesotho</option>
                                <option value="LR">Liberia</option>
                                <option value="LY">Libyan Arab Jamahiriya</option>
                                <option value="LI">Liechtenstein</option>
                                <option value="LT">Lithuania</option>
                                <option value="LU">Luxembourg</option>
                                <option value="MO">Macao</option>
                                <option value="MK">Macedonia, the Former Yugoslav Republic of</option>
                                <option value="MG">Madagascar</option>
                                <option value="MW">Malawi</option>
                                <option value="MY">Malaysia</option>
                                <option value="MV">Maldives</option>
                                <option value="ML">Mali</option>
                                <option value="MT">Malta</option>
                                <option value="MH">Marshall Islands</option>
                                <option value="MQ">Martinique</option>
                                <option value="MR">Mauritania</option>
                                <option value="MU">Mauritius</option>
                                <option value="YT">Mayotte</option>
                                <option value="MX">Mexico</option>
                                <option value="FM">Micronesia, Federated States of</option>
                                <option value="MD">Moldova, Republic of</option>
                                <option value="MC">Monaco</option>
                                <option value="MN">Mongolia</option>
                                <option value="ME">Montenegro</option>
                                <option value="MS">Montserrat</option>
                                <option value="MA">Morocco</option>
                                <option value="MZ">Mozambique</option>
                                <option value="MM">Myanmar</option>
                                <option value="NA">Namibia</option>
                                <option value="NR">Nauru</option>
                                <option value="NP">Nepal</option>
                                <option value="NL">Netherlands</option>
                                <option value="AN">Netherlands Antilles</option>
                                <option value="NC">New Caledonia</option>
                                <option value="NZ">New Zealand</option>
                                <option value="NI">Nicaragua</option>
                                <option value="NE">Niger</option>
                                <option value="NG">Nigeria</option>
                                <option value="NU">Niue</option>
                                <option value="NF">Norfolk Island</option>
                                <option value="MP">Northern Mariana Islands</option>
                                <option value="NO">Norway</option>
                                <option value="OM">Oman</option>
                                <option value="PK">Pakistan</option>
                                <option value="PW">Palau</option>
                                <option value="PS">Palestinian Territory, Occupied</option>
                                <option value="PA">Panama</option>
                                <option value="PG">Papua New Guinea</option>
                                <option value="PY">Paraguay</option>
                                <option value="PE">Peru</option>
                                <option value="PH">Philippines</option>
                                <option value="PN">Pitcairn</option>
                                <option value="PL">Poland</option>
                                <option value="PT">Portugal</option>
                                <option value="PR">Puerto Rico</option>
                                <option value="QA">Qatar</option>
                                <option value="RE">Reunion</option>
                                <option value="RO">Romania</option>
                                <option value="RU">Russian Federation</option>
                                <option value="RW">Rwanda</option>
                                <option value="BL">Saint Barthelemy</option>
                                <option value="SH">Saint Helena</option>
                                <option value="KN">Saint Kitts and Nevis</option>
                                <option value="LC">Saint Lucia</option>
                                <option value="MF">Saint Martin</option>
                                <option value="PM">Saint Pierre and Miquelon</option>
                                <option value="VC">Saint Vincent and the Grenadines</option>
                                <option value="WS">Samoa</option>
                                <option value="SM">San Marino</option>
                                <option value="ST">Sao Tome and Principe</option>
                                <option value="SA">Saudi Arabia</option>
                                <option value="SN">Senegal</option>
                                <option value="RS">Serbia</option>
                                <option value="CS">Serbia and Montenegro</option>
                                <option value="SC">Seychelles</option>
                                <option value="SL">Sierra Leone</option>
                                <option value="SG">Singapore</option>
                                <option value="SX">Sint Maarten</option>
                                <option value="SK">Slovakia</option>
                                <option value="SI">Slovenia</option>
                                <option value="SB">Solomon Islands</option>
                                <option value="SO">Somalia</option>
                                <option value="ZA">South Africa</option>
                                <option value="GS">South Georgia and the South Sandwich Islands</option>
                                <option value="SS">South Sudan</option>
                                <option value="ES">Spain</option>
                                <option value="LK">Sri Lanka</option>
                                <option value="SD">Sudan</option>
                                <option value="SR">Suriname</option>
                                <option value="SJ">Svalbard and Jan Mayen</option>
                                <option value="SZ">Swaziland</option>
                                <option value="SE">Sweden</option>
                                <option value="CH">Switzerland</option>
                                <option value="SY">Syrian Arab Republic</option>
                                <option value="TW">Taiwan, Province of China</option>
                                <option value="TJ">Tajikistan</option>
                                <option value="TZ">Tanzania, United Republic of</option>
                                <option value="TH">Thailand</option>
                                <option value="TL">Timor-Leste</option>
                                <option value="TG">Togo</option>
                                <option value="TK">Tokelau</option>
                                <option value="TO">Tonga</option>
                                <option value="TT">Trinidad and Tobago</option>
                                <option value="TN">Tunisia</option>
                                <option value="TR">Turkey</option>
                                <option value="TM">Turkmenistan</option>
                                <option value="TC">Turks and Caicos Islands</option>
                                <option value="TV">Tuvalu</option>
                                <option value="UA">Ukraine</option>
                                <option value="AE">United Arab Emirates</option>
                                <option value="GB">United Kingdom</option>
                                <option value="US">United States</option>
                                <option value="UM">United States Minor Outlying Islands</option>
                                <option value="UY">Uruguay</option>
                                <option value="UZ">Uzbekistan</option>
                                <option value="VU">Vanuatu</option>
                                <option value="VE">Venezuela</option>
                                <option value="VN">Viet Nam</option>
                                <option value="VG">Virgin Islands, British</option>
                                <option value="VI">Virgin Islands, U.s.</option>
                                <option value="WF">Wallis and Futuna</option>
                                <option value="EH">Western Sahara</option>
                                <option value="YE">Yemen</option>
                                <option value="ZM">Zambia</option>
                                <option value="ZW">Zimbabwe</option>
                              </select>
                              <small class="invalid-select" *ngIf="paymentForm.get('countrya'+i).touched && paymentForm.get('countrya'+i).hasError('required')">Your nationality is required!</small>

                            </div>
                          </div>
                          <div class="col-md-6 _pb-10">
                            <div class="row row-col-gap" data-gutter="10">
                              <div class="col-md-6 ">
                                <label>Date of birth</label>
                                <div class="theme-payment-page-form-item form-group">
                                  <i class="fas fa-angle-down"></i>
                                  <select class="form-control" 
                                  name="month_of_birtha{{i}}"
                                  id="month_of_birtha{{i}}"
                                  tabindex="0"
                                  formControlName="month_of_birtha{{i}}">
                                    <option value="">Month</option>
                                    <option value="01">January</option>
                                    <option value="02">February</option>
                                    <option value="03">March</option>
                                    <option value="04">April</option>
                                    <option value="05">May</option>
                                    <option value="06">June</option>
                                    <option value="07">July</option>
                                    <option value="08">August</option>
                                    <option value="09">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                  </select>

                                </div>
                              </div>
                              <div class="col-md-3 ">
                                <label>Day</label>
                                <div class="theme-payment-page-form-item form-group ">
                                  <input class="form-control" type="text" placeholder="DD" 
                                  name="day_of_birtha{{i}}"
                                  id="day_of_birtha{{i}}"
                                  tabindex="0"
                                  formControlName="day_of_birtha{{i}}">
                                
                                </div>
                              </div>
                              <div class="col-md-3 ">
                                <label> Year</label>
                                <div class="theme-payment-page-form-item form-group">
                                  <input class="form-control" type="text" placeholder="YYYY" 
                                  name="year_of_birtha{{i}}"
                                  id="year_of_birtha{{i}}"
                                  tabindex="0"
                                  formControlName="year_of_birtha{{i}}">
                                  
                                </div>
                              </div>
                            </div>
                            <small class="invalid-select" *ngIf="paymentForm.get('month_of_birtha'+i).touched && paymentForm.get('month_of_birtha'+i).hasError('required')">Your month of birth is required!</small>
                            <small class="invalid-select" *ngIf="paymentForm.get('day_of_birtha'+i).touched && paymentForm.get('day_of_birtha'+i).hasError('minlength')"> Your day of birth should be valid</small>
                            <small class="invalid-select" *ngIf="paymentForm.get('day_of_birtha'+i).touched && paymentForm.get('day_of_birtha'+i).hasError('maxlength')">, Your day of birth should be valid</small>
                            <small class="invalid-select" *ngIf="paymentForm.get('day_of_birtha'+i).touched && paymentForm.get('day_of_birtha'+i).hasError('required')">, Your day of birth is required!</small>
                            <small class="invalid-select" *ngIf="paymentForm.get('year_of_birtha'+i).touched && paymentForm.get('year_of_birtha'+i).hasError('minlength')">, Your year of birth should be valid</small>
                            <small class="invalid-select" *ngIf="paymentForm.get('year_of_birtha'+i).touched && paymentForm.get('year_of_birtha'+i).hasError('maxlength')">, Your year of birth should be valid</small>
                            <small class="invalid-select" *ngIf="paymentForm.get('year_of_birtha'+i).touched && paymentForm.get('year_of_birtha'+i).hasError('required')">, Your year of birth is required!</small>
                          
                          </div>
                          <div class="col-md-6 _pb-10">
                            <label>Gender</label>
                            <div class="theme-payment-page-form-item form-group">
                              <i class="fas fa-angle-down"></i>
                              <select class="form-control" 
                                  name="gendera{{i}}"
                                  id="gendera{{i}}"
                                  tabindex="0"
                                  formControlName="gendera{{i}}">
                                <option value="">Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </select>
                              <small class="invalid-select" *ngIf="paymentForm.get('gendera'+i).touched && paymentForm.get('gendera'+i).hasError('required')">Your gender is required!</small>
                            </div>
                          </div>
                          <div *ngIf="i==0">
                            <div class="col-md-7 ">
                              <div class="theme-payment-page-form-item form-group">
                                <label>Email</label>
                                <input class="form-control" type="text" placeholder="Email"
                                    name="emaila{{i}}"
                                    id="emaila{{i}}"
                                    tabindex="0"
                                    formControlName="emaila{{i}}">
                                    <small class="invalid-select" *ngIf="paymentForm.get('emaila'+i).touched && paymentForm.get('emaila'+i).hasError('required')">Your email is required!</small>
                                    <small class="invalid-select" *ngIf="paymentForm.get('emaila'+i).touched && paymentForm.get('emaila'+i).hasError('emaila'+i)">Your email should be a email address!</small>
                              </div>
                            </div>
                            <div class="col-md-5 ">
                              <div class="theme-payment-page-form-item form-group">
                                <label>Phone</label>
                                <input class="form-control" type="text" placeholder="Phone number" 
                                    name="phonenoa{{i}}"
                                    id="phonenoa{{i}}"
                                    tabindex="0"
                                    formControlName="phonenoa{{i}}">
                                    <small class="invalid-select" *ngIf="paymentForm.get('phonenoa'+i).touched && paymentForm.get('phonenoa'+i).hasError('minlength')">Your phone number should not be less or greater than 10. </small>
                                    <small class="invalid-select" *ngIf="paymentForm.get('phonenoa'+i).touched && paymentForm.get('phonenoa'+i).hasError('required')">Your phone number is required!</small>
                                    <small class="invalid-select" *ngIf="paymentForm.get('phonenoa'+i).touched && paymentForm.get('phonenoa'+i).hasError('pattern')">Your phone number should be valid!</small>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                       
                      </div>
                      
                    </div>
                
                
                  <div *ngFor="let seat of groupedPassengers.child;let i = index ">
                    <div class="theme-payment-page-sections-item-passenger">
                      <h3 class="theme-payment-page-form-title">Child {{i+1}} </h3>
                      <div class="row row-col-gap" data-gutter="20">
                        <div class="col-md-6 ">
                          <div class="theme-payment-page-form-item form-group">
                            <label>Name</label>
                            <input class="form-control" type="text" placeholder="Name"
                                name="fnamec{{i}}"
                                id="fnamec{{i}}"
                                tabindex="0"
                                formControlName="fnamec{{i}}">
                                <small class="invalid-select" *ngIf="paymentForm.get('fnamec'+i).touched && paymentForm.get('fnamec'+i).hasError('pattern')">Child Name should not contain numbers or spaces!</small>
                                <small class="invalid-select" *ngIf="paymentForm.get('fnamec'+i).touched && paymentForm.get('fnamec'+i).hasError('required')">Child Name is required!</small> 
                          </div>
                        </div>
                        <div class="col-md-6 ">
                          <div class="theme-payment-page-form-item form-group">
                            <label>Last Name</label>
                            <input class="form-control" type="text" placeholder="Last Name" 
                                name="lnamec{{i}}"
                                id="lnamec{{i}}"
                                tabindex="0"
                                formControlName="lnamec{{i}}">
                                <small class="invalid-select" *ngIf="paymentForm.get('lnamec'+i).touched && paymentForm.get('lnamec'+i).hasError('pattern')">Child last name should not contain numbers or spaces!</small>
                                <small class="invalid-select" *ngIf="paymentForm.get('lnamec'+i).touched && paymentForm.get('lnamec'+i).hasError('required')">Child last name is required!</small>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label>Nationality</label>
                          <div class="theme-payment-page-form-item form-group">
                            <i class="fas fa-angle-down"></i>
                            
                            <select class="form-control"   
                            name="countryc{{i}}"
                            id="countryc{{i}}"
                            tabindex="0"
                            formControlName="countryc{{i}}">
                              <option value="">Select nationality</option>
                              <option value="KE">Kenya</option>
                              <option value="UG">Uganda</option>
                              <option value="AF">Afghanistan</option>
                              <option value="AX">Aland Islands</option>
                              <option value="AL">Albania</option>
                              <option value="DZ">Algeria</option>
                              <option value="AS">American Samoa</option>
                              <option value="AD">Andorra</option>
                              <option value="AO">Angola</option>
                              <option value="AI">Anguilla</option>
                              <option value="AQ">Antarctica</option>
                              <option value="AG">Antigua and Barbuda</option>
                              <option value="AR">Argentina</option>
                              <option value="AM">Armenia</option>
                              <option value="AW">Aruba</option>
                              <option value="AU">Australia</option>
                              <option value="AT">Austria</option>
                              <option value="AZ">Azerbaijan</option>
                              <option value="BS">Bahamas</option>
                              <option value="BH">Bahrain</option>
                              <option value="BD">Bangladesh</option>
                              <option value="BB">Barbados</option>
                              <option value="BY">Belarus</option>
                              <option value="BE">Belgium</option>
                              <option value="BZ">Belize</option>
                              <option value="BJ">Benin</option>
                              <option value="BM">Bermuda</option>
                              <option value="BT">Bhutan</option>
                              <option value="BO">Bolivia</option>
                              <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                              <option value="BA">Bosnia and Herzegovina</option>
                              <option value="BW">Botswana</option>
                              <option value="BV">Bouvet Island</option>
                              <option value="BR">Brazil</option>
                              <option value="IO">British Indian Ocean Territory</option>
                              <option value="BN">Brunei Darussalam</option>
                              <option value="BG">Bulgaria</option>
                              <option value="BF">Burkina Faso</option>
                              <option value="BI">Burundi</option>
                              <option value="KH">Cambodia</option>
                              <option value="CM">Cameroon</option>
                              <option value="CA">Canada</option>
                              <option value="CV">Cape Verde</option>
                              <option value="KY">Cayman Islands</option>
                              <option value="CF">Central African Republic</option>
                              <option value="TD">Chad</option>
                              <option value="CL">Chile</option>
                              <option value="CN">China</option>
                              <option value="CX">Christmas Island</option>
                              <option value="CC">Cocos (Keeling) Islands</option>
                              <option value="CO">Colombia</option>
                              <option value="KM">Comoros</option>
                              <option value="CG">Congo</option>
                              <option value="CD">Congo, Democratic Republic of the Congo</option>
                              <option value="CK">Cook Islands</option>
                              <option value="CR">Costa Rica</option>
                              <option value="CI">Cote D'Ivoire</option>
                              <option value="HR">Croatia</option>
                              <option value="CU">Cuba</option>
                              <option value="CW">Curacao</option>
                              <option value="CY">Cyprus</option>
                              <option value="CZ">Czech Republic</option>
                              <option value="DK">Denmark</option>
                              <option value="DJ">Djibouti</option>
                              <option value="DM">Dominica</option>
                              <option value="DO">Dominican Republic</option>
                              <option value="EC">Ecuador</option>
                              <option value="EG">Egypt</option>
                              <option value="SV">El Salvador</option>
                              <option value="GQ">Equatorial Guinea</option>
                              <option value="ER">Eritrea</option>
                              <option value="EE">Estonia</option>
                              <option value="ET">Ethiopia</option>
                              <option value="FK">Falkland Islands (Malvinas)</option>
                              <option value="FO">Faroe Islands</option>
                              <option value="FJ">Fiji</option>
                              <option value="FI">Finland</option>
                              <option value="FR">France</option>
                              <option value="GF">French Guiana</option>
                              <option value="PF">French Polynesia</option>
                              <option value="TF">French Southern Territories</option>
                              <option value="GA">Gabon</option>
                              <option value="GM">Gambia</option>
                              <option value="GE">Georgia</option>
                              <option value="DE">Germany</option>
                              <option value="GH">Ghana</option>
                              <option value="GI">Gibraltar</option>
                              <option value="GR">Greece</option>
                              <option value="GL">Greenland</option>
                              <option value="GD">Grenada</option>
                              <option value="GP">Guadeloupe</option>
                              <option value="GU">Guam</option>
                              <option value="GT">Guatemala</option>
                              <option value="GG">Guernsey</option>
                              <option value="GN">Guinea</option>
                              <option value="GW">Guinea-Bissau</option>
                              <option value="GY">Guyana</option>
                              <option value="HT">Haiti</option>
                              <option value="HM">Heard Island and Mcdonald Islands</option>
                              <option value="VA">Holy See (Vatican City State)</option>
                              <option value="HN">Honduras</option>
                              <option value="HK">Hong Kong</option>
                              <option value="HU">Hungary</option>
                              <option value="IS">Iceland</option>
                              <option value="IN">India</option>
                              <option value="ID">Indonesia</option>
                              <option value="IR">Iran, Islamic Republic of</option>
                              <option value="IQ">Iraq</option>
                              <option value="IE">Ireland</option>
                              <option value="IM">Isle of Man</option>
                              <option value="IL">Israel</option>
                              <option value="IT">Italy</option>
                              <option value="JM">Jamaica</option>
                              <option value="JP">Japan</option>
                              <option value="JE">Jersey</option>
                              <option value="JO">Jordan</option>
                              <option value="KZ">Kazakhstan</option>
                              <option value="KI">Kiribati</option>
                              <option value="KP">Korea, Democratic People's Republic of</option>
                              <option value="KR">Korea, Republic of</option>
                              <option value="XK">Kosovo</option>
                              <option value="KW">Kuwait</option>
                              <option value="KG">Kyrgyzstan</option>
                              <option value="LA">Lao People's Democratic Republic</option>
                              <option value="LV">Latvia</option>
                              <option value="LB">Lebanon</option>
                              <option value="LS">Lesotho</option>
                              <option value="LR">Liberia</option>
                              <option value="LY">Libyan Arab Jamahiriya</option>
                              <option value="LI">Liechtenstein</option>
                              <option value="LT">Lithuania</option>
                              <option value="LU">Luxembourg</option>
                              <option value="MO">Macao</option>
                              <option value="MK">Macedonia, the Former Yugoslav Republic of</option>
                              <option value="MG">Madagascar</option>
                              <option value="MW">Malawi</option>
                              <option value="MY">Malaysia</option>
                              <option value="MV">Maldives</option>
                              <option value="ML">Mali</option>
                              <option value="MT">Malta</option>
                              <option value="MH">Marshall Islands</option>
                              <option value="MQ">Martinique</option>
                              <option value="MR">Mauritania</option>
                              <option value="MU">Mauritius</option>
                              <option value="YT">Mayotte</option>
                              <option value="MX">Mexico</option>
                              <option value="FM">Micronesia, Federated States of</option>
                              <option value="MD">Moldova, Republic of</option>
                              <option value="MC">Monaco</option>
                              <option value="MN">Mongolia</option>
                              <option value="ME">Montenegro</option>
                              <option value="MS">Montserrat</option>
                              <option value="MA">Morocco</option>
                              <option value="MZ">Mozambique</option>
                              <option value="MM">Myanmar</option>
                              <option value="NA">Namibia</option>
                              <option value="NR">Nauru</option>
                              <option value="NP">Nepal</option>
                              <option value="NL">Netherlands</option>
                              <option value="AN">Netherlands Antilles</option>
                              <option value="NC">New Caledonia</option>
                              <option value="NZ">New Zealand</option>
                              <option value="NI">Nicaragua</option>
                              <option value="NE">Niger</option>
                              <option value="NG">Nigeria</option>
                              <option value="NU">Niue</option>
                              <option value="NF">Norfolk Island</option>
                              <option value="MP">Northern Mariana Islands</option>
                              <option value="NO">Norway</option>
                              <option value="OM">Oman</option>
                              <option value="PK">Pakistan</option>
                              <option value="PW">Palau</option>
                              <option value="PS">Palestinian Territory, Occupied</option>
                              <option value="PA">Panama</option>
                              <option value="PG">Papua New Guinea</option>
                              <option value="PY">Paraguay</option>
                              <option value="PE">Peru</option>
                              <option value="PH">Philippines</option>
                              <option value="PN">Pitcairn</option>
                              <option value="PL">Poland</option>
                              <option value="PT">Portugal</option>
                              <option value="PR">Puerto Rico</option>
                              <option value="QA">Qatar</option>
                              <option value="RE">Reunion</option>
                              <option value="RO">Romania</option>
                              <option value="RU">Russian Federation</option>
                              <option value="RW">Rwanda</option>
                              <option value="BL">Saint Barthelemy</option>
                              <option value="SH">Saint Helena</option>
                              <option value="KN">Saint Kitts and Nevis</option>
                              <option value="LC">Saint Lucia</option>
                              <option value="MF">Saint Martin</option>
                              <option value="PM">Saint Pierre and Miquelon</option>
                              <option value="VC">Saint Vincent and the Grenadines</option>
                              <option value="WS">Samoa</option>
                              <option value="SM">San Marino</option>
                              <option value="ST">Sao Tome and Principe</option>
                              <option value="SA">Saudi Arabia</option>
                              <option value="SN">Senegal</option>
                              <option value="RS">Serbia</option>
                              <option value="CS">Serbia and Montenegro</option>
                              <option value="SC">Seychelles</option>
                              <option value="SL">Sierra Leone</option>
                              <option value="SG">Singapore</option>
                              <option value="SX">Sint Maarten</option>
                              <option value="SK">Slovakia</option>
                              <option value="SI">Slovenia</option>
                              <option value="SB">Solomon Islands</option>
                              <option value="SO">Somalia</option>
                              <option value="ZA">South Africa</option>
                              <option value="GS">South Georgia and the South Sandwich Islands</option>
                              <option value="SS">South Sudan</option>
                              <option value="ES">Spain</option>
                              <option value="LK">Sri Lanka</option>
                              <option value="SD">Sudan</option>
                              <option value="SR">Suriname</option>
                              <option value="SJ">Svalbard and Jan Mayen</option>
                              <option value="SZ">Swaziland</option>
                              <option value="SE">Sweden</option>
                              <option value="CH">Switzerland</option>
                              <option value="SY">Syrian Arab Republic</option>
                              <option value="TW">Taiwan, Province of China</option>
                              <option value="TJ">Tajikistan</option>
                              <option value="TZ">Tanzania, United Republic of</option>
                              <option value="TH">Thailand</option>
                              <option value="TL">Timor-Leste</option>
                              <option value="TG">Togo</option>
                              <option value="TK">Tokelau</option>
                              <option value="TO">Tonga</option>
                              <option value="TT">Trinidad and Tobago</option>
                              <option value="TN">Tunisia</option>
                              <option value="TR">Turkey</option>
                              <option value="TM">Turkmenistan</option>
                              <option value="TC">Turks and Caicos Islands</option>
                              <option value="TV">Tuvalu</option>
                              <option value="UA">Ukraine</option>
                              <option value="AE">United Arab Emirates</option>
                              <option value="GB">United Kingdom</option>
                              <option value="US">United States</option>
                              <option value="UM">United States Minor Outlying Islands</option>
                              <option value="UY">Uruguay</option>
                              <option value="UZ">Uzbekistan</option>
                              <option value="VU">Vanuatu</option>
                              <option value="VE">Venezuela</option>
                              <option value="VN">Viet Nam</option>
                              <option value="VG">Virgin Islands, British</option>
                              <option value="VI">Virgin Islands, U.s.</option>
                              <option value="WF">Wallis and Futuna</option>
                              <option value="EH">Western Sahara</option>
                              <option value="YE">Yemen</option>
                              <option value="ZM">Zambia</option>
                              <option value="ZW">Zimbabwe</option>
                            </select>
                            <small class="invalid-select" *ngIf="paymentForm.get('countryc'+i).touched && paymentForm.get('countryc'+i).hasError('required')">Child nationality is required!</small>
                          </div>
                        </div>
                        <div class="col-md-6 ">
                          <div class="row row-col-gap" data-gutter="10">
                            <div class="col-md-6 ">
                              <label>Date of birth</label>
                              <div class="theme-payment-page-form-item form-group">
                                <i class="fas fa-angle-down"></i>
                                <select class="form-control" 
                                name="month_of_birthc{{i}}"
                                id="month_of_birthc{{i}}"
                                tabindex="0"
                                formControlName="month_of_birthc{{i}}">
                                  <option value="">Month</option>
                                  <option value="01">January</option>
                                    <option value="02">February</option>
                                    <option value="03">March</option>
                                    <option value="04">April</option>
                                    <option value="05">May</option>
                                    <option value="06">June</option>
                                    <option value="07">July</option>
                                    <option value="08">August</option>
                                    <option value="09">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-3 ">
                              <label>Day</label>
                              <div class="theme-payment-page-form-item form-group ">
                                <input class="form-control" type="text" placeholder="DD" 
                                name="day_of_birthc{{i}}"
                                id="day_of_birthc{{i}}"
                                tabindex="0"
                                formControlName="day_of_birthc{{i}}">
                              </div>
                            </div>
                            <div class="col-md-3 ">
                              <label> Year</label>
                              <div class="theme-payment-page-form-item form-group">
                                <input class="form-control" type="text" placeholder="YYYY" 
                                name="year_of_birthc{{i}}"
                                id="year_of_birthc{{i}}"
                                tabindex="0"
                                formControlName="year_of_birthc{{i}}">
                              </div>
                            </div>
                          </div>
                          <small class="invalid-select" *ngIf="paymentForm.get('month_of_birthc'+i).touched && paymentForm.get('month_of_birthc'+i).hasError('required')">Child month of birth is required!</small>
                          <small class="invalid-select" *ngIf="paymentForm.get('day_of_birthc'+i).touched && paymentForm.get('day_of_birthc'+i).hasError('minlength')"> Child day of birth should be valid</small>
                          <small class="invalid-select" *ngIf="paymentForm.get('day_of_birthc'+i).touched && paymentForm.get('day_of_birthc'+i).hasError('maxlength')">, Child day of birth should be valid</small>
                          <small class="invalid-select" *ngIf="paymentForm.get('day_of_birthc'+i).touched && paymentForm.get('day_of_birthc'+i).hasError('required')">, Child day of birth is required!</small>
                          <small class="invalid-select" *ngIf="paymentForm.get('year_of_birthc'+i).touched && paymentForm.get('year_of_birthc'+i).hasError('minlength')">, Child year of birth should be valid</small>
                          <small class="invalid-select" *ngIf="paymentForm.get('year_of_birthc'+i).touched && paymentForm.get('year_of_birthc'+i).hasError('maxlength')">, Child year of birth should be valid</small>
                          <small class="invalid-select" *ngIf="paymentForm.get('year_of_birthc'+i).touched && paymentForm.get('year_of_birthc'+i).hasError('required')">, Child year of birth is required!</small>
                        
                        </div>
                        <div class="col-md-6 ">
                          <label>Gender</label>
                          <div class="theme-payment-page-form-item form-group">
                            <i class="fas fa-angle-down"></i>
                            <select class="form-control" 
                                name="genderc{{i}}"
                                id="genderc{{i}}"
                                tabindex="0"
                                formControlName="genderc{{i}}">
                              <option value="">Gender</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                            <small class="invalid-select" *ngIf="paymentForm.get('genderc'+i).touched && paymentForm.get('genderc'+i).hasError('required')">Child gender is required!</small>

                          </div>
                        </div>
                        
                      </div>
                    </div>
                    
                  </div>
                  
                  <div *ngFor="let seat of groupedPassengers.infant;let i = index ">
                    <div class="theme-payment-page-sections-item-passenger">
                      <h3 class="theme-payment-page-form-title">Infant {{i+1}} </h3>
                      <div class="row row-col-gap" data-gutter="20">
                        <div class="col-md-6 ">
                          <div class="theme-payment-page-form-item form-group">
                            <label>Name</label>
                            <input class="form-control" type="text" placeholder="Name"
                                name="fnamei{{i}}"
                                id="fnamei{{i}}"
                                tabindex="0"
                                formControlName="fnamei{{i}}">
                                <small class="invalid-select" *ngIf="paymentForm.get('fnamei'+i).touched && paymentForm.get('fnamei'+i).hasError('pattern')">Infant Name should not contain numbers or spaces!</small>
                                <small class="invalid-select" *ngIf="paymentForm.get('fnamei'+i).touched && paymentForm.get('fnamei'+i).hasError('required')">Infant Name is required!</small> 
                          </div>
                        </div>
                        <div class="col-md-6 ">
                          <div class="theme-payment-page-form-item form-group">
                            <label>Last Name</label>
                            <input class="form-control" type="text" placeholder="Last Name" 
                                name="lnamei{{i}}"
                                id="lnamei{{i}}"
                                tabindex="0"
                                formControlName="lnamei{{i}}">
                                <small class="invalid-select" *ngIf="paymentForm.get('lnamei'+i).touched && paymentForm.get('lnamei'+i).hasError('pattern')">Infant last name should not contain numbers or spaces!</small>
                                <small class="invalid-select" *ngIf="paymentForm.get('lnamei'+i).touched && paymentForm.get('lnamei'+i).hasError('required')">Infant last name is required!</small>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label>Nationality</label>
                          <div class="theme-payment-page-form-item form-group">
                            <i class="fas fa-angle-down"></i>
                            <select class="form-control"   
                            name="countryi{{i}}"
                            id="countryi{{i}}"
                            tabindex="0"
                            formControlName="countryi{{i}}">
                              <option value="">Select nationality</option>
                              <option value="KE">Kenya</option>
                              <option value="UG">Uganda</option>
                              <option value="AF">Afghanistan</option>
                              <option value="AX">Aland Islands</option>
                              <option value="AL">Albania</option>
                              <option value="DZ">Algeria</option>
                              <option value="AS">American Samoa</option>
                              <option value="AD">Andorra</option>
                              <option value="AO">Angola</option>
                              <option value="AI">Anguilla</option>
                              <option value="AQ">Antarctica</option>
                              <option value="AG">Antigua and Barbuda</option>
                              <option value="AR">Argentina</option>
                              <option value="AM">Armenia</option>
                              <option value="AW">Aruba</option>
                              <option value="AU">Australia</option>
                              <option value="AT">Austria</option>
                              <option value="AZ">Azerbaijan</option>
                              <option value="BS">Bahamas</option>
                              <option value="BH">Bahrain</option>
                              <option value="BD">Bangladesh</option>
                              <option value="BB">Barbados</option>
                              <option value="BY">Belarus</option>
                              <option value="BE">Belgium</option>
                              <option value="BZ">Belize</option>
                              <option value="BJ">Benin</option>
                              <option value="BM">Bermuda</option>
                              <option value="BT">Bhutan</option>
                              <option value="BO">Bolivia</option>
                              <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                              <option value="BA">Bosnia and Herzegovina</option>
                              <option value="BW">Botswana</option>
                              <option value="BV">Bouvet Island</option>
                              <option value="BR">Brazil</option>
                              <option value="IO">British Indian Ocean Territory</option>
                              <option value="BN">Brunei Darussalam</option>
                              <option value="BG">Bulgaria</option>
                              <option value="BF">Burkina Faso</option>
                              <option value="BI">Burundi</option>
                              <option value="KH">Cambodia</option>
                              <option value="CM">Cameroon</option>
                              <option value="CA">Canada</option>
                              <option value="CV">Cape Verde</option>
                              <option value="KY">Cayman Islands</option>
                              <option value="CF">Central African Republic</option>
                              <option value="TD">Chad</option>
                              <option value="CL">Chile</option>
                              <option value="CN">China</option>
                              <option value="CX">Christmas Island</option>
                              <option value="CC">Cocos (Keeling) Islands</option>
                              <option value="CO">Colombia</option>
                              <option value="KM">Comoros</option>
                              <option value="CG">Congo</option>
                              <option value="CD">Congo, Democratic Republic of the Congo</option>
                              <option value="CK">Cook Islands</option>
                              <option value="CR">Costa Rica</option>
                              <option value="CI">Cote D'Ivoire</option>
                              <option value="HR">Croatia</option>
                              <option value="CU">Cuba</option>
                              <option value="CW">Curacao</option>
                              <option value="CY">Cyprus</option>
                              <option value="CZ">Czech Republic</option>
                              <option value="DK">Denmark</option>
                              <option value="DJ">Djibouti</option>
                              <option value="DM">Dominica</option>
                              <option value="DO">Dominican Republic</option>
                              <option value="EC">Ecuador</option>
                              <option value="EG">Egypt</option>
                              <option value="SV">El Salvador</option>
                              <option value="GQ">Equatorial Guinea</option>
                              <option value="ER">Eritrea</option>
                              <option value="EE">Estonia</option>
                              <option value="ET">Ethiopia</option>
                              <option value="FK">Falkland Islands (Malvinas)</option>
                              <option value="FO">Faroe Islands</option>
                              <option value="FJ">Fiji</option>
                              <option value="FI">Finland</option>
                              <option value="FR">France</option>
                              <option value="GF">French Guiana</option>
                              <option value="PF">French Polynesia</option>
                              <option value="TF">French Southern Territories</option>
                              <option value="GA">Gabon</option>
                              <option value="GM">Gambia</option>
                              <option value="GE">Georgia</option>
                              <option value="DE">Germany</option>
                              <option value="GH">Ghana</option>
                              <option value="GI">Gibraltar</option>
                              <option value="GR">Greece</option>
                              <option value="GL">Greenland</option>
                              <option value="GD">Grenada</option>
                              <option value="GP">Guadeloupe</option>
                              <option value="GU">Guam</option>
                              <option value="GT">Guatemala</option>
                              <option value="GG">Guernsey</option>
                              <option value="GN">Guinea</option>
                              <option value="GW">Guinea-Bissau</option>
                              <option value="GY">Guyana</option>
                              <option value="HT">Haiti</option>
                              <option value="HM">Heard Island and Mcdonald Islands</option>
                              <option value="VA">Holy See (Vatican City State)</option>
                              <option value="HN">Honduras</option>
                              <option value="HK">Hong Kong</option>
                              <option value="HU">Hungary</option>
                              <option value="IS">Iceland</option>
                              <option value="IN">India</option>
                              <option value="ID">Indonesia</option>
                              <option value="IR">Iran, Islamic Republic of</option>
                              <option value="IQ">Iraq</option>
                              <option value="IE">Ireland</option>
                              <option value="IM">Isle of Man</option>
                              <option value="IL">Israel</option>
                              <option value="IT">Italy</option>
                              <option value="JM">Jamaica</option>
                              <option value="JP">Japan</option>
                              <option value="JE">Jersey</option>
                              <option value="JO">Jordan</option>
                              <option value="KZ">Kazakhstan</option>
                              <option value="KI">Kiribati</option>
                              <option value="KP">Korea, Democratic People's Republic of</option>
                              <option value="KR">Korea, Republic of</option>
                              <option value="XK">Kosovo</option>
                              <option value="KW">Kuwait</option>
                              <option value="KG">Kyrgyzstan</option>
                              <option value="LA">Lao People's Democratic Republic</option>
                              <option value="LV">Latvia</option>
                              <option value="LB">Lebanon</option>
                              <option value="LS">Lesotho</option>
                              <option value="LR">Liberia</option>
                              <option value="LY">Libyan Arab Jamahiriya</option>
                              <option value="LI">Liechtenstein</option>
                              <option value="LT">Lithuania</option>
                              <option value="LU">Luxembourg</option>
                              <option value="MO">Macao</option>
                              <option value="MK">Macedonia, the Former Yugoslav Republic of</option>
                              <option value="MG">Madagascar</option>
                              <option value="MW">Malawi</option>
                              <option value="MY">Malaysia</option>
                              <option value="MV">Maldives</option>
                              <option value="ML">Mali</option>
                              <option value="MT">Malta</option>
                              <option value="MH">Marshall Islands</option>
                              <option value="MQ">Martinique</option>
                              <option value="MR">Mauritania</option>
                              <option value="MU">Mauritius</option>
                              <option value="YT">Mayotte</option>
                              <option value="MX">Mexico</option>
                              <option value="FM">Micronesia, Federated States of</option>
                              <option value="MD">Moldova, Republic of</option>
                              <option value="MC">Monaco</option>
                              <option value="MN">Mongolia</option>
                              <option value="ME">Montenegro</option>
                              <option value="MS">Montserrat</option>
                              <option value="MA">Morocco</option>
                              <option value="MZ">Mozambique</option>
                              <option value="MM">Myanmar</option>
                              <option value="NA">Namibia</option>
                              <option value="NR">Nauru</option>
                              <option value="NP">Nepal</option>
                              <option value="NL">Netherlands</option>
                              <option value="AN">Netherlands Antilles</option>
                              <option value="NC">New Caledonia</option>
                              <option value="NZ">New Zealand</option>
                              <option value="NI">Nicaragua</option>
                              <option value="NE">Niger</option>
                              <option value="NG">Nigeria</option>
                              <option value="NU">Niue</option>
                              <option value="NF">Norfolk Island</option>
                              <option value="MP">Northern Mariana Islands</option>
                              <option value="NO">Norway</option>
                              <option value="OM">Oman</option>
                              <option value="PK">Pakistan</option>
                              <option value="PW">Palau</option>
                              <option value="PS">Palestinian Territory, Occupied</option>
                              <option value="PA">Panama</option>
                              <option value="PG">Papua New Guinea</option>
                              <option value="PY">Paraguay</option>
                              <option value="PE">Peru</option>
                              <option value="PH">Philippines</option>
                              <option value="PN">Pitcairn</option>
                              <option value="PL">Poland</option>
                              <option value="PT">Portugal</option>
                              <option value="PR">Puerto Rico</option>
                              <option value="QA">Qatar</option>
                              <option value="RE">Reunion</option>
                              <option value="RO">Romania</option>
                              <option value="RU">Russian Federation</option>
                              <option value="RW">Rwanda</option>
                              <option value="BL">Saint Barthelemy</option>
                              <option value="SH">Saint Helena</option>
                              <option value="KN">Saint Kitts and Nevis</option>
                              <option value="LC">Saint Lucia</option>
                              <option value="MF">Saint Martin</option>
                              <option value="PM">Saint Pierre and Miquelon</option>
                              <option value="VC">Saint Vincent and the Grenadines</option>
                              <option value="WS">Samoa</option>
                              <option value="SM">San Marino</option>
                              <option value="ST">Sao Tome and Principe</option>
                              <option value="SA">Saudi Arabia</option>
                              <option value="SN">Senegal</option>
                              <option value="RS">Serbia</option>
                              <option value="CS">Serbia and Montenegro</option>
                              <option value="SC">Seychelles</option>
                              <option value="SL">Sierra Leone</option>
                              <option value="SG">Singapore</option>
                              <option value="SX">Sint Maarten</option>
                              <option value="SK">Slovakia</option>
                              <option value="SI">Slovenia</option>
                              <option value="SB">Solomon Islands</option>
                              <option value="SO">Somalia</option>
                              <option value="ZA">South Africa</option>
                              <option value="GS">South Georgia and the South Sandwich Islands</option>
                              <option value="SS">South Sudan</option>
                              <option value="ES">Spain</option>
                              <option value="LK">Sri Lanka</option>
                              <option value="SD">Sudan</option>
                              <option value="SR">Suriname</option>
                              <option value="SJ">Svalbard and Jan Mayen</option>
                              <option value="SZ">Swaziland</option>
                              <option value="SE">Sweden</option>
                              <option value="CH">Switzerland</option>
                              <option value="SY">Syrian Arab Republic</option>
                              <option value="TW">Taiwan, Province of China</option>
                              <option value="TJ">Tajikistan</option>
                              <option value="TZ">Tanzania, United Republic of</option>
                              <option value="TH">Thailand</option>
                              <option value="TL">Timor-Leste</option>
                              <option value="TG">Togo</option>
                              <option value="TK">Tokelau</option>
                              <option value="TO">Tonga</option>
                              <option value="TT">Trinidad and Tobago</option>
                              <option value="TN">Tunisia</option>
                              <option value="TR">Turkey</option>
                              <option value="TM">Turkmenistan</option>
                              <option value="TC">Turks and Caicos Islands</option>
                              <option value="TV">Tuvalu</option>
                              <option value="UA">Ukraine</option>
                              <option value="AE">United Arab Emirates</option>
                              <option value="GB">United Kingdom</option>
                              <option value="US">United States</option>
                              <option value="UM">United States Minor Outlying Islands</option>
                              <option value="UY">Uruguay</option>
                              <option value="UZ">Uzbekistan</option>
                              <option value="VU">Vanuatu</option>
                              <option value="VE">Venezuela</option>
                              <option value="VN">Viet Nam</option>
                              <option value="VG">Virgin Islands, British</option>
                              <option value="VI">Virgin Islands, U.s.</option>
                              <option value="WF">Wallis and Futuna</option>
                              <option value="EH">Western Sahara</option>
                              <option value="YE">Yemen</option>
                              <option value="ZM">Zambia</option>
                              <option value="ZW">Zimbabwe</option>
                            </select>
                            <small class="invalid-select" *ngIf="paymentForm.get('countryi'+i).touched && paymentForm.get('countryi'+i).hasError('required')">Infant nationality is required!</small>

                          </div>
                        </div>
                        <div class="col-md-6 ">
                          <div class="row row-col-gap" data-gutter="10">
                            <div class="col-md-6 ">
                              <label>Date of birth</label>
                              <div class="theme-payment-page-form-item form-group">
                                <i class="fas fa-angle-down"></i>
                                <select class="form-control" 
                                name="month_of_birthi{{i}}"
                                id="month_of_birthi{{i}}"
                                tabindex="0"
                                formControlName="month_of_birthi{{i}}">
                                <option value="01">January</option>
                                <option value="02">February</option>
                                <option value="03">March</option>
                                <option value="04">April</option>
                                <option value="05">May</option>
                                <option value="06">June</option>
                                <option value="07">July</option>
                                <option value="08">August</option>
                                <option value="09">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-md-3 ">
                              <label>Day</label>
                              <div class="theme-payment-page-form-item form-group ">
                                <input class="form-control" type="text" placeholder="DD" 
                                name="day_of_birthi{{i}}"
                                id="day_of_birthi{{i}}"
                                tabindex="0"
                                formControlName="day_of_birthi{{i}}">
                              </div>
                            </div>
                            <div class="col-md-3 ">
                              <label> Year</label>
                              <div class="theme-payment-page-form-item form-group">
                                <input class="form-control" type="text" placeholder="YYYY" 
                                name="year_of_birthi{{i}}"
                                id="year_of_birthi{{i}}"
                                tabindex="0"
                                formControlName="year_of_birthi{{i}}">
                              </div>
                            </div>
                          </div>
                          <small class="invalid-select" *ngIf="paymentForm.get('month_of_birthi'+i).touched && paymentForm.get('month_of_birthi'+i).hasError('required')">Infant month of birth is required!</small>
                          <small class="invalid-select" *ngIf="paymentForm.get('day_of_birthi'+i).touched && paymentForm.get('day_of_birthi'+i).hasError('minlength')"> Infant day of birth should be valid</small>
                          <small class="invalid-select" *ngIf="paymentForm.get('day_of_birthi'+i).touched && paymentForm.get('day_of_birthi'+i).hasError('maxlength')">, Infant day of birth should be valid</small>
                          <small class="invalid-select" *ngIf="paymentForm.get('day_of_birthi'+i).touched && paymentForm.get('day_of_birthi'+i).hasError('required')">, Infant day of birth is required!</small>
                          <small class="invalid-select" *ngIf="paymentForm.get('year_of_birthi'+i).touched && paymentForm.get('year_of_birthi'+i).hasError('minlength')">, Infant year of birth should be valid</small>
                          <small class="invalid-select" *ngIf="paymentForm.get('year_of_birthi'+i).touched && paymentForm.get('year_of_birthi'+i).hasError('maxlength')">, Infant year of birth should be valid</small>
                          <small class="invalid-select" *ngIf="paymentForm.get('year_of_birthi'+i).touched && paymentForm.get('year_of_birthi'+i).hasError('required')">, Infant year of birth is required!</small>
                        
                        </div>
                        <div class="col-md-6 ">
                          <label>Gender</label>
                          <div class="theme-payment-page-form-item form-group">
                            <i class="fas fa-angle-down"></i>
                            <select class="form-control" 
                                name="genderi{{i}}"
                                id="genderi{{i}}"
                                tabindex="0"
                                formControlName="genderi{{i}}">
                              <option value="">Gender</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                            <small class="invalid-select" *ngIf="paymentForm.get('genderi'+i).touched && paymentForm.get('genderi'+i).hasError('required')">Infant gender is required!</small>

                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                  

                  <div class="theme-payment-page-sections-item">
                    <div class="theme-payment-page-booking">
                      <div class="theme-payment-page-booking-header">
                        <h3 class="theme-payment-page-booking-title">Choose payment option</h3>
                      </div>
                      <!-- Payment Options -->
                      <div class="payment-options">
                        <label class="payment-option">
                          <input type="radio" name="paymentMethod" value="mpesa" (change)="setPaymentMethod('mpesa')" checked />
                          <span class="radio-label"></span>
                          <span class="payment-label">Mpesa</span>
                          <img src="assets/img/brands/mpesa.jpg" alt="Mpesa Logo" class="payment-logo" />
                        </label>
                  
                        <label class="payment-option">
                          <input type="radio" name="paymentMethod" value="card" (change)="setPaymentMethod('card')" />
                          <span class="radio-label"></span>
                          <span class="payment-label">Card</span>
                          <img src="assets/img/brands/visa.jpg" alt="Card Logo" class="payment-logo" />
                        </label>
                      </div>
                      <div class="theme-payment-page-booking-header">
                        <h3 class="theme-payment-page-booking-title">Total</h3>
                        <p class="theme-payment-page-booking-subtitle">
                          By clicking the Pay button, you agree to our terms and conditions and money-back guarantee. Thank you for trusting our service.
                        </p>
                        <p class="theme-payment-page-booking-price">{{ bookingData.total_currency }} {{ bookingData.total_amount }}</p>
                      </div>
                  
                     
                  
                      <!-- Submit Button -->
                      <div class="btn-cntnr right">
                        <button
                          (click)="submitForm()"
                          type="submit"
                          [ngClass]="paymentForm.valid ? 'enabled' : 'disabled'"
                          [disabled]="paymentForm.invalid"
                          class="c-btn u-link"
                        >
                          <div class="u-ripple">
                            <span class="u-ripple-circle"></span>
                          </div>
                          <img *ngIf="loading" src="assets/img/gifs/76.gif" alt="Loading">
                          <span *ngIf="!loading">PAY</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-4 ">
          <div class="sticky-col" style="">
            <div class="theme-sidebar-section _mb-10">
              <h5 class="theme-sidebar-section-title">Booking Summary</h5>
              <ul class="theme-sidebar-section-summary-list">
                <li>Economy,
                  {{ counts.adult > 1 ? counts.adult + ' Adults' : (counts.adult === 1 ? counts.adult + ' Adult' : '') }}
                  {{ counts.child > 1 ? counts.child + ' Children' : (counts.child === 1 ? counts.child + ' Child' : '') }}
                  {{ counts.infant > 1 ? counts.infant + ' Infants' : (counts.infant === 1 ? counts.infant + ' Infant' : '') }}                  </li>
                <li>Depart:  {{bookingData.slices[0].arriving_day.split("T")[0]}}</li>
        
              </ul>
            </div>
            <div class="theme-sidebar-section _mb-10">
              <h5 class="theme-sidebar-section-title">Charges</h5>
              <div class="theme-sidebar-section-charges">
                <ul class="theme-sidebar-section-charges-list">
                  <li class="theme-sidebar-section-charges-item">
                    <h5 class="theme-sidebar-section-charges-item-title">Economy,
                      {{ counts.adult > 1 ? counts.adult + ' Adults' : (counts.adult === 1 ? counts.adult + ' Adult' : '') }}
                      {{ counts.child > 1 ? counts.child + ' Children' : (counts.child === 1 ? counts.child + ' Child' : '') }}
                      {{ counts.infant > 1 ? counts.infant + ' Infants' : (counts.infant === 1 ? counts.infant + ' Infant' : '') }}                      </h5>
                    <p class="theme-sidebar-section-charges-item-subtitle">Economy</p>
                    <p class="theme-sidebar-section-charges-item-price">{{bookingData.total_currency}} {{  bookingData.total_amount }}</p>
                  </li>
      
                </ul>
                <p class="theme-sidebar-section-charges-total">Total
                  <span>{{bookingData.total_currency}} {{  bookingData.total_amount }}</span>
                </p>
               
              </div>
              
            </div>
            <div *ngIf="!isExpired" class="countdown-container">
              <div class="countdown-timer">
                <div class="time-box">
                  <span class="time-value">{{ minutes | number: '2.0' }}</span>
                  <span class="time-label">MINUTES</span>
                </div>
                <div class="separator">:</div>
                <div class="time-box">
                  <span class="time-value">{{ seconds | number: '2.0' }}</span>
                  <span class="time-label">SECONDS</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


</div>
<button type="button" id="sessionExpired" class="btn btn-info btn-lg" data-toggle="modal" data-target="#expiredModal">Open Modal</button>

<!-- Bootstrap Modal for Expiration -->
<div id="expiredModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button id="dismissModal" type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="modalLabel">Booking Expired</h4>
      </div>
      <div class="modal-body">
        <p>Your booking session has expired. Please try again or refresh the page to start over.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closePopup()">Close</button>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>