import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmService, DataService, ItineraryServiceService } from '../services';
import { Error } from '../models/index'
import { Base64 } from 'js-base64';

@Component({
  selector: 'app-card-process',
  templateUrl: './card-process.component.html',
  styleUrls: ['./card-process.component.css']
})
export class CardProcessComponent {

  internalBookingReference: string | null = null;

    sub:any;
    confirmData:any;
    res:any;
    isError:boolean;
    errorMessage:any;
    successMessage:any;
    isWaiting:boolean;
    isSaving:boolean
    waitingMessage:any;
    api_error:Error;
    isHidden:boolean = true;
    passData:any;
    started=0;
    int1:any;
    int2:any;
    int3:any;

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private service: ConfirmService,
      private itenaryservice:ItineraryServiceService,
      private dataService: DataService,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.internalBookingReference = params.get('internal_booking_reference');
    });


    this.getBookingStatus(this.internalBookingReference)

  }


  backToHome(){
    document.getElementById("closeConfirmDialog").click();
    clearInterval(this.int1);
    clearInterval(this.int2);
    clearInterval(this.int3);
    this.router.navigate(['/index.html']);
  }

  retryConfirmation(){
    document.getElementById("closeConfirmDialog").click();
    clearInterval(this.int1);
    clearInterval(this.int2);
    clearInterval(this.int3);
    setTimeout(function () {
      this.confirmMpesa(this.confirmData);
    }, 3000);

  }


  getBookingStatus(internal_id){
    this.isWaiting=true;
    this.isSaving=false;
    this.waitingMessage="Processing your payments please wait.....";
    this.itenaryservice.getBookingsCard(internal_id).subscribe(
      data  => {
        this.res=data.data[0];
        var self = this;
        console.log(this.res)
        if(this.res.status =='success'){
          this.isWaiting=false;
          this.successMessage="Payment processed successfully";
          let f=btoa(JSON.stringify(this.res))
          clearInterval(self.int1);
          clearInterval(self.int2);
          clearInterval(self.int3);
          this.router.navigate(['/booking-successful', f])
         
        }else if(this.res.status == "hold") {
          console.log('JHoled')
          let paymentStatus = false;
        
          paymentStatus = this.res.payments && this.res.payments.payment_code ? true : false;

          if(paymentStatus){
            this.isWaiting=false;
            this.successMessage="Payment processed successfully";
            let f=btoa(JSON.stringify(this.res))
            clearInterval(self.int1);
            clearInterval(self.int2);
            clearInterval(self.int3);
            this.router.navigate(['/booking-successful', f])
          }else{
            this.isWaiting=true;
                this.waitingMessage="Processing your payments please wait.....";
                  var self = this;
                            setTimeout(function () {
                              if(self.started==0){
                                 self.started=1
                                 var i = 1000;
                                 self.int2 = setInterval(function () {
                                  self.getBookingStatus(internal_id)
                                  i += 1000;
                                  if (i >= 25000){
                                    clearInterval(self.int1);
                                    clearInterval(self.int2);
                                    clearInterval(self.int3);
                                    document.getElementById("openReloadButton").click();
                                    // self.router.navigate(['/index.html']);
                                  } ;
                                }, 10000);
                              }
                          }, 1000);
          }
        } else if(this.res.status == 'cancelled' ){
          var self = this;
          this.isWaiting=false;
          this.isError=true;
          this.errorMessage="Sorry your payment request was canceled. Please try again";
          
          let bdata=this.confirmData
          let fdata={
            "flight_data":this.confirmData,
            "isError":true,
            "errorMessage":'cancelled'
          }
               clearInterval(self.int1);
                clearInterval(self.int2);
                clearInterval(self.int3);
          let pay=Base64.encode(JSON.stringify(fdata));
          let paymessage=btoa("Opps sory mate!");
          this.dataService.changePaymentMessage(pay);
          this.router.navigate(['/payments', paymessage])
          
          if (this.res.status == "hold"){
            

              setTimeout(function () {
                clearInterval(self.int1);
                clearInterval(self.int2);
                clearInterval(self.int3);
                document.getElementById("openReloadButton").click();
                // self.router.navigate(['/index.html']);
              }, 10000);

            }
                    setTimeout(function () {
                      if(self.started==0){
                         self.started=1
                         var i = 1000;
                          self.int1 = setInterval(function () {
                          self.getBookingStatus(internal_id)
                          i += 1000;
                          if (i >= 25000){
                            clearInterval(self.int1);
                            clearInterval(self.int2);
                            clearInterval(self.int3);
                            document.getElementById("openReloadButton").click();
                            // this.router.navigate(['/index.html']);
                          } ;
                        }, 10000);
                      }
                  }, 1000);
        }else{

                this.isWaiting=true;
                this.waitingMessage="Processing your payments please wait.....";
                  var self = this;
                            setTimeout(function () {
                              if(self.started==0){
                                 self.started=1
                                 var i = 1000;
                                 self.int2 = setInterval(function () {
                                  self.getBookingStatus(internal_id)
                                  i += 1000;
                                  if (i >= 25000){
                                    clearInterval(self.int1);
                                    clearInterval(self.int2);
                                    clearInterval(self.int3);
                                    document.getElementById("openReloadButton").click();
                                    // self.router.navigate(['/index.html']);
                                  } ;
                                }, 10000);
                              }
                          }, 1000);
        }


      },
      error  => {
        this.isError=true;
        this.isWaiting=true;
        this.waitingMessage="Processing your payments please wait.....";
        this.api_error=new Error().deserialize(error.error.errors)
        var self = this;
                  setTimeout(function () {
                    if(self.started==0){
                       self.started=1
                       var i = 1000;
                        self.int3 = setInterval(function () {
                        self.getBookingStatus(internal_id)
                        i += 1000;
                        if (i >= 25000){
                            clearInterval(self.int1);
                            clearInterval(self.int2);
                            clearInterval(self.int3);
                            self.router.navigate(['/index.html']);
                        } ;
                      }, 10000);
                    }
                }, 1000);

      }
    );
  }

}
